import { ValidationError, useForm } from "@formspree/react";
import freeEmailDomains from "free-email-domains"; // Import the list of free email domains
import "moment/locale/en-gb";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import CartContext from "../context/CartContext";

function ContactFormMultiStep() {
	const {
		cart,
		settingVal,
		numberOfPeople,
		budgetPerDesk,
		selectedLocation,
		budgetMinMax,
	} = React.useContext(CartContext);
	const [state, handleSubmit] = useForm("mgvewjkp");

	const uniqueId = uuidv4();

	const [currentStep, setCurrentStep] = useState(1);
	const [formData, setFormData] = useState({
		dateAndTime: "",
		maxDesksRequired:
			numberOfPeople && !numberOfPeople.reset ? numberOfPeople.max : "",
		fullName: "",
		phone: "",
		workEmail: "",
		companyName: "",
		acceptPolicy: false,
	});

	const [selectedOffices, setSelectedOffices] = useState([]);
	const [proptiesList, setProptiesList] = useState([]);
	const [providersList, setProvidersList] = useState([]);
	const [proptiesIdList, setProptiesIdList] = useState([]);

	const [errors, setErrors] = useState({});
	const [emailError, setEmailError] = useState("");

	const formGroupStyles = "mb-3 mb-xl-4";
	const formInputStyles = " border-input";

	// Create options for react-select
	const officeOptions = cart.map((property) => ({
		value: property.airtableId,
		label: property.name,
	}));

	// Initialize selected offices with all options
	useEffect(() => {
		if (cart.length > 0) {
			setSelectedOffices(officeOptions);
		}
	}, [cart]);

	// Update hidden fields when selected offices change
	useEffect(() => {
		const selectedProperties = selectedOffices
			.map((option) => {
				return cart.find((property) => property.airtableId === option.value);
			})
			.filter(Boolean);

		const selectedPropertyNames = selectedProperties.map(
			(property) => property.name
		);
		const selectedPropertyIds = selectedProperties.map(
			(property) => property.airtableId
		);
		const selectedProviders = selectedProperties.map(
			(property) => property.provider || "Unknown"
		);

		setProptiesList(selectedPropertyNames);
		setProptiesIdList(selectedPropertyIds);
		setProvidersList(selectedProviders);
	}, [selectedOffices, cart]);

	useEffect(() => {
		if (state.succeeded) {
			settingVal([], "cart");
			window.location.href = "/thank-you-viewing";
		}
	}, [state.succeeded]);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	const handleDateChange = (date) => {
		setFormData((prev) => ({ ...prev, dateAndTime: date }));
	};

	const handleOfficesChange = (selectedOptions) => {
		setSelectedOffices(selectedOptions || []);
	};

	const validateStep1 = () => {
		let hasError = false;
		let stepErrors = {};

		if (selectedOffices.length === 0) {
			stepErrors.officesError = "Please select at least one office";
			hasError = true;
		}

		if (!formData.dateAndTime) {
			stepErrors.dateAndTimeError = "Please select date and time";
			hasError = true;
		}

		if (!formData.maxDesksRequired || Number(formData.maxDesksRequired) <= 0) {
			stepErrors.maxDesksRequiredError =
				"Please enter a valid number of desks required";
			hasError = true;
		}

		setErrors(stepErrors);

		return !hasError;
	};

	const handleNext = (e) => {
		e.preventDefault();
		if (validateStep1()) {
			setCurrentStep(2);
		}
	};

	const onSubmit = (event) => {
		event.preventDefault();
		let hasError = false;
		let stepErrors = {};

		// Validate Full Name
		if (!formData["Full name"]) {
			stepErrors.fullNameError = "Please enter your full name";
			hasError = true;
		}

		// Validate Phone
		if (!formData["Phone"]) {
			stepErrors.phoneError = "Please enter your phone number";
			hasError = true;
		}

		// Validate Work Email
		if (!formData["Work email"]) {
			stepErrors.workEmailError = "Please enter your work email";
			hasError = true;
		} else {
			const emailDomain = formData["Work email"].split("@")[1]?.toLowerCase();

			if (
				!emailDomain ||
				freeEmailDomains.includes(emailDomain) ||
				emailDomain.split(".").length < 2
			) {
				stepErrors.workEmailError = "Please enter a valid work email address";
				hasError = true;
			}
		}

		// Validate Company Name
		if (!formData["Company name"]) {
			stepErrors.companyNameError = "Please enter your company name";
			hasError = true;
		}

		// Validate Accept Policy
		if (!formData["accept-the-policy"]) {
			stepErrors.acceptPolicyError = "You must accept the privacy policy";
			hasError = true;
		}

		setErrors(stepErrors);

		if (hasError) {
			return;
		}

		handleSubmit(event);
	};

	return (
		<Form className="w-100" onSubmit={onSubmit}>
			{/* Step 1 */}
			<div style={{ display: currentStep === 1 ? "block" : "none" }}>
				<Form.Group className={`${formGroupStyles}`} controlId="Date and time">
					<Form.Label className="houschka">
						Preferred viewing date and time *
					</Form.Label>
					<Datetime
						dateFormat="Do MMM YYYY"
						timeFormat="HH:mm"
						inputProps={{
							placeholder: "Date and time",
							required: true,
							name: "Date and time",
							className: `${formInputStyles} form.control w-100 date-picker-new`,
						}}
						timeConstraints={{ minutes: { step: 30 } }}
						onChange={handleDateChange}
					/>
					{errors.dateAndTimeError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.dateAndTimeError}
						</div>
					)}
				</Form.Group>
				<Form.Group className={`${formGroupStyles}`} controlId="Offices">
					<Form.Label className="houschka">Offices *</Form.Label>
					<Select
						isMulti
						placeholder="Select offices for viewing"
						options={officeOptions}
						value={selectedOffices}
						onChange={handleOfficesChange}
						className="basic-multi-select"
						classNamePrefix="select"
					/>
					{errors.officesError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.officesError}
						</div>
					)}
				</Form.Group>
				<Form.Group
					className={`${formGroupStyles}`}
					controlId="MaxDesksRequired"
				>
					<Form.Label className="houschka">Desks required *</Form.Label>
					<Form.Control
						required
						name="MaxDesksRequired"
						placeholder="Enter the maximum number of desks required"
						type="number"
						min="1"
						className={`${formInputStyles}`}
						value={formData.maxDesksRequired}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								maxDesksRequired: e.target.value,
							}))
						}
					/>
					{errors.maxDesksRequiredError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.maxDesksRequiredError}
						</div>
					)}
				</Form.Group>
				<Button
					variant="secondary"
					className="px-3 py-2 w-100  text-light-black montserrat-bold"
					onClick={handleNext}
				>
					Next
				</Button>
			</div>

			{/* Step 2 */}
			<div style={{ display: currentStep === 2 ? "block" : "none" }}>
				<Form.Group className={`${formGroupStyles}`} controlId="Full name">
					<Form.Label className="houschka">Full name *</Form.Label>
					<Form.Control
						required
						name="Full name"
						placeholder="Adam Krill"
						type="text"
						className={`${formInputStyles}`}
						value={formData["Full name"]}
						onChange={handleChange}
					/>
					{errors.fullNameError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.fullNameError}
						</div>
					)}
				</Form.Group>
				<Form.Group className={`${formGroupStyles}`} controlId="Phone">
					<Form.Label className="houschka">Phone *</Form.Label>
					<Form.Control
						required
						type="tel"
						name="Phone"
						placeholder="+44 203 016 0270"
						className={`${formInputStyles}`}
						value={formData["Phone"]}
						onChange={handleChange}
					/>
					{errors.phoneError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.phoneError}
						</div>
					)}
				</Form.Group>

				<Form.Group className={`${formGroupStyles}`} controlId="Work email">
					<Form.Label className="houschka">Work email *</Form.Label>
					<Form.Control
						required
						name="Work email"
						type="email"
						placeholder="hello@yourofficepeople.co.uk"
						className={`${formInputStyles}`}
						value={formData["Work email"]}
						onChange={handleChange}
					/>
					{errors.workEmailError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.workEmailError}
						</div>
					)}
				</Form.Group>

				<Form.Group className={`${formGroupStyles}`} controlId="Company name">
					<Form.Label className="houschka">Company name *</Form.Label>
					<Form.Control
						required
						name="Company name"
						placeholder="Your Office People"
						type="text"
						className={`${formInputStyles}`}
						value={formData["Company name"]}
						onChange={handleChange}
					/>
					{errors.companyNameError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.companyNameError}
						</div>
					)}
				</Form.Group>

				<Form.Group className="mb-3" controlId="accept-the-policy">
					<Form.Check
						required
						label={
							<span>
								Upon submitting my message, I accept the{" "}
								<a
									className="text-decoration-underline"
									target="_blank"
									rel="noreferrer"
									href="/policies/privacy-notice"
								>
									privacy notice
								</a>
							</span>
						}
						className="w-auto"
						type="checkbox"
						name="accept-the-policy"
						checked={formData["accept-the-policy"]}
						onChange={handleChange}
					/>
					{errors.acceptPolicyError && (
						<div style={{ color: "red", marginTop: "5px" }}>
							{errors.acceptPolicyError}
						</div>
					)}
				</Form.Group>

				<Button
					variant="secondary"
					className="px-3 py-2 w-100  text-light-black montserrat-bold"
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					Submit
				</Button>
			</div>

			{/* Hidden Fields (always included in the form) */}
			<Form.Group className="mb-3 d-none" controlId="properties">
				<Form.Control
					name="properties"
					placeholder="properties"
					value={proptiesList.join(", ")}
					as="textarea"
					rows={3}
				/>
			</Form.Group>
			<ValidationError
				prefix="properties"
				field="properties"
				errors={state.errors}
			/>

			<Form.Group className="mb-3 d-none " controlId="providers">
				<Form.Control
					name="providers"
					placeholder="providers"
					value={providersList.join(", ")}
					as="textarea"
					rows={3}
				/>
			</Form.Group>
			<ValidationError
				prefix="providers"
				field="providers"
				errors={state.errors}
			/>

			<Form.Group className="mb-3 d-none" controlId="propertyIds">
				<Form.Control
					name="propertyIds"
					placeholder="propertyIds"
					value={proptiesIdList.join(", ")}
					as="textarea"
					rows={3}
				/>
			</Form.Group>
			<ValidationError
				prefix="propertyIds"
				field="propertyIds"
				errors={state.errors}
			/>

			<Form.Group className="mb-3 d-none" controlId="uid">
				<Form.Control
					name="uid"
					value={uniqueId}
					placeholder="uid"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="uid" field="uid" errors={state.errors} />

			<Form.Group className="mb-3 d-none" controlId="Budget">
				<Form.Control
					name="Budget"
					value={
						budgetPerDesk !== budgetMinMax
							? !budgetPerDesk.perDesk
								? "£" +
								  budgetPerDesk.min +
								  " " +
								  "-" +
								  " " +
								  "£" +
								  budgetPerDesk.max +
								  " pcm"
								: "£" +
								  budgetPerDesk.minPerDesk +
								  " " +
								  "-" +
								  " " +
								  "£" +
								  budgetPerDesk.maxPerDesk +
								  " Per Desk"
							: "Budget not set by customer"
					}
					placeholder="Budget"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Budget" field="Budget" errors={state.errors} />
			<Form.Group className="mb-3 d-none" controlId="Location">
				<Form.Control
					name="Location"
					value={selectedLocation}
					placeholder="Location"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="Location"
				field="Location"
				errors={state.errors}
			/>
		</Form>
	);
}

export default ContactFormMultiStep;
