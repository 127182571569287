import { ValidationError, useForm } from "@formspree/react";
import freeEmailDomains from "free-email-domains"; // Import the list of free email domains
import "moment/locale/en-gb";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import CartContext from "../context/CartContext";

function ContactForm() {
	const {
		cart,
		settingVal,
		numberOfPeople,
		budgetPerDesk,
		selectedLocation,
		budgetMinMax,
	} = React.useContext(CartContext);
	const [state, handleSubmit] = useForm("mgvewjkp");

	const uniqueId = uuidv4();

	// Initialize maxDesksRequired with numberOfPeople.max if available
	const [maxDesksRequired, setMaxDesksRequired] = useState(
		numberOfPeople && !numberOfPeople.reset ? numberOfPeople.max : ""
	);

	const [selectedOffices, setSelectedOffices] = useState([]);
	const [proptiesList, setProptiesList] = useState([]);
	const [providersList, setProvidersList] = useState([]);
	const [proptiesIdList, setProptiesIdList] = useState([]);
	const [officesError, setOfficesError] = useState("");
	const [emailError, setEmailError] = useState("");

	const formGroupStyles = "mb-2 mb-xl-4";
	const formInputStyles = " border-input";

	// Create options for react-select
	const officeOptions = cart.map((property) => ({
		value: property.airtableId,
		label: property.name,
	}));

	// Initialize selected offices with all options
	useEffect(() => {
		if (cart.length > 0) {
			setSelectedOffices(officeOptions);
		}
	}, [cart]);

	// Update hidden fields when selected offices change
	useEffect(() => {
		const selectedProperties = selectedOffices
			.map((option) => {
				return cart.find((property) => property.airtableId === option.value);
			})
			.filter(Boolean);

		const selectedPropertyNames = selectedProperties.map(
			(property) => property.name
		);
		const selectedPropertyIds = selectedProperties.map(
			(property) => property.airtableId
		);
		const selectedProviders = selectedProperties.map(
			(property) => property.provider || "Unknown"
		);

		setProptiesList(selectedPropertyNames);
		setProptiesIdList(selectedPropertyIds);
		setProvidersList(selectedProviders);
	}, [selectedOffices, cart]);

	const handleOfficesChange = (selectedOptions) => {
		setSelectedOffices(selectedOptions || []);
	};

	useEffect(() => {
		if (state.succeeded) {
			settingVal([], "cart");
			window.location.href = "/thank-you-viewing";
		}
	}, [state.succeeded]);

	const onSubmit = (event) => {
		event.preventDefault();
		let hasError = false;

		// Validate Offices
		if (selectedOffices.length === 0) {
			setOfficesError("Please select at least one office");
			hasError = true;
		} else {
			setOfficesError("");
		}

		// Validate Work Email
		const workEmail = event.target.elements["Work email"].value;
		const emailDomain = workEmail.split("@")[1]?.toLowerCase();

		if (
			!emailDomain ||
			freeEmailDomains.includes(emailDomain) ||
			emailDomain.split(".").length < 2
		) {
			setEmailError("Please enter a valid work email address");
			hasError = true;
		} else {
			setEmailError("");
		}

		if (hasError) {
			return;
		}

		handleSubmit(event);
	};

	return (
		<Form className="w-100" onSubmit={onSubmit}>
			<Form.Group className={`${formGroupStyles}`} controlId="Date and time">
				<Form.Label className="houschka">
					Preferred viewing date and time *
				</Form.Label>
				<Datetime
					dateFormat="Do MMM YYYY"
					timeFormat="HH:mm"
					inputProps={{
						placeholder: "Date and time",
						required: true,
						name: "Date and time",
						className: `${formInputStyles} form.control w-100 date-picker-new`,
					}}
					timeConstraints={{ minutes: { step: 30 } }}
				/>
			</Form.Group>
			<ValidationError
				prefix="Date and time"
				field="Date and time"
				errors={state.errors}
			/>
			<Form.Group className={`${formGroupStyles}`} controlId="Offices">
				<Form.Label className="houschka">Offices *</Form.Label>
				<Select
					isMulti
					placeholder="Select offices for viewing"
					options={officeOptions}
					value={selectedOffices}
					onChange={handleOfficesChange}
					className="basic-multi-select"
					classNamePrefix="select"
				/>
				{officesError && (
					<div style={{ color: "red", marginTop: "5px" }}>{officesError}</div>
				)}
			</Form.Group>
			<Form.Group className={`${formGroupStyles}`} controlId="MaxDesksRequired">
				<Form.Label className="houschka">Desks required *</Form.Label>
				<Form.Control
					required
					name="Desks required"
					placeholder="Enter the maximum number of desks required"
					type="number"
					min="1"
					className={`${formInputStyles}`}
					value={maxDesksRequired}
					onChange={(e) => setMaxDesksRequired(e.target.value)}
				/>
			</Form.Group>
			<ValidationError
				prefix="Desks required"
				field="Desks required"
				errors={state.errors}
			/>
			<Form.Group className={`${formGroupStyles}`} controlId="Full name">
				<Form.Label className="houschka">Full name *</Form.Label>
				<Form.Control
					required
					name="Full name"
					placeholder="Adam Krill"
					type="text"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError
				prefix="Full name"
				field="Full name"
				errors={state.errors}
			/>
			<Form.Group className={`${formGroupStyles}`} controlId="Phone">
				<Form.Label className="houschka">Phone *</Form.Label>
				<Form.Control
					required
					type="tel"
					name="Phone"
					placeholder="+44 203 016 0270"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Phone" field="Phone" errors={state.errors} />

			<Form.Group className={`${formGroupStyles}`} controlId="Work email">
				<Form.Label className="houschka">Work email *</Form.Label>
				<Form.Control
					required
					name="Work email"
					type="email"
					placeholder="hello@yourofficepeople.co.uk"
					className={`${formInputStyles}`}
				/>
				{emailError && (
					<div style={{ color: "red", marginTop: "5px" }}>{emailError}</div>
				)}
			</Form.Group>
			<ValidationError
				prefix="Work email"
				field="Work email"
				errors={state.errors}
			/>

			<Form.Group className={`${formGroupStyles}`} controlId="Company name">
				<Form.Label className="houschka">Company name *</Form.Label>
				<Form.Control
					required
					name="Company name"
					placeholder="Your Office People"
					type="text"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError
				prefix="Company name"
				field="Company name"
				errors={state.errors}
			/>

			{/* Hidden Fields */}
			<Form.Group className="mb-3 d-none" controlId="properties">
				<Form.Control
					name="properties"
					placeholder="properties"
					value={proptiesList.join(", ")}
					as="textarea"
					rows={3}
				/>
			</Form.Group>
			<ValidationError
				prefix="properties"
				field="properties"
				errors={state.errors}
			/>

			<Form.Group className="mb-3 d-none " controlId="providers">
				<Form.Control
					name="providers"
					placeholder="providers"
					value={providersList.join(", ")}
					as="textarea"
					rows={3}
				/>
			</Form.Group>
			<ValidationError
				prefix="providers"
				field="providers"
				errors={state.errors}
			/>

			<Form.Group className="mb-3 d-none" controlId="propertyIds">
				<Form.Control
					name="propertyIds"
					placeholder="propertyIds"
					value={proptiesIdList.join(", ")}
					as="textarea"
					rows={3}
				/>
			</Form.Group>
			<ValidationError
				prefix="propertyIds"
				field="propertyIds"
				errors={state.errors}
			/>

			<Form.Group className="mb-3 d-none" controlId="uid">
				<Form.Control
					name="uid"
					value={uniqueId}
					placeholder="uid"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="uid" field="uid" errors={state.errors} />

			<Form.Group className="mb-3 d-none" controlId="Budget">
				<Form.Control
					name="Budget"
					value={
						budgetPerDesk !== budgetMinMax
							? !budgetPerDesk.perDesk
								? "£" +
								  budgetPerDesk.min +
								  " " +
								  "-" +
								  " " +
								  "£" +
								  budgetPerDesk.max +
								  " pcm"
								: "£" +
								  budgetPerDesk.minPerDesk +
								  " " +
								  "-" +
								  " " +
								  "£" +
								  budgetPerDesk.maxPerDesk +
								  " Per Desk"
							: "Budget not set by customer"
					}
					placeholder="Budget"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Budget" field="Budget" errors={state.errors} />
			<Form.Group className="mb-3 d-none" controlId="Location">
				<Form.Control
					name="Location"
					value={selectedLocation}
					placeholder="Location"
					type="text"
				/>
			</Form.Group>
			<ValidationError
				prefix="Location"
				field="Location"
				errors={state.errors}
			/>

			<Form.Group className="mb-3" controlId="accept-the-policy">
				<Form.Check
					required
					label={
						<span>
							Upon submitting my message, I accept the{" "}
							<a
								className="text-decoration-underline"
								target="_blank"
								rel="noreferrer"
								href="/policies/privacy-notice"
							>
								privacy notice
							</a>
						</span>
					}
					className="w-auto"
					type="checkbox"
					name="accept-the-policy"
					value="true"
				/>
			</Form.Group>
			<ValidationError
				prefix="accept-the-policy"
				field="accept-the-policy"
				errors={state.errors}
			/>

			<Button
				variant="secondary"
				className="px-3 py-2 w-100  text-light-black montserrat-bold"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Submit
			</Button>
		</Form>
	);
}

export default ContactForm;
