import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaMapMarkerAlt, FaTrain } from "react-icons/fa";
import CartContext from "../context/CartContext";
import CardPillTagSection from "./card-pill-tag-section";
import ImageSlider from "./image-slider";

const slugify = require("slugify");

const createSlug = (value) => {
	// Ensure the value is a string or provide a default empty string if it's not
	const stringValue = value ? String(value) : "";
	return slugify(stringValue);
};

function round5(x) {
	return Math.ceil(x / 5) * 5;
}
const FavouriteCard = ({ property, index }) => {
	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		selectedLocation,
		budgetPerDesk,
		numberOfPeople,
	} = React.useContext(CartContext);

	var floors = [];
	if (property?.floorsAvailable !== null) {
		floors =
			property?.floorsAvailable
				?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
				.slice() || [];
	}

	// const refTb = useRef();
	// const tbShow = useOnScreen(refTb, "0px");
	// useEffect(() => {
	// 	onLocationChangeScroll(
	// 		property.locationLatitude,
	// 		property.locationLongitude
	// 	);
	// }, [tbShow]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	const handleHeartClick = (clickEvent, shortlistProp, addOrRemove) => {
		clickEvent.preventDefault();
		addOrRemove(shortlistProp);
	};

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formSubmitted2, setFormSubmitted2] = useState(false);

	const handleFormSuccess = () => {
		setFormSubmitted(true);
	};
	const handleFormSuccess2 = () => {
		setFormSubmitted2(true);
	};

	const [vidShow, setVidShow] = useState(false);
	const [formShow, setFormShow] = useState(false);

	const handleVidShow = (e) => {
		e.preventDefault();
		setVidShow(true);
	};
	const handleVidClose = () => setVidShow(false);

	const handleFormShow = (e) => {
		e.preventDefault();
		setFormShow(true);
	};
	const handleFormClose = () => setFormShow(false);

	const lowerCapacity = Math.max(property?.desksFrom, numberOfPeople?.min);

	return (
		<div className=" px-0 mx-0 position-relative">
			<div
				className="bg-white h-100 px-0 mx-0 listings-property-card-shadow rounded-3"
				onMouseEnter={() => {
					onLocationChangeScroll(
						property?.locationLatitude,
						property?.locationLongitude
					);
				}}
			>
				<Link
					to={`/listings/${createSlug(property?.name)}-${property?.fixedId}`}
				>
					<div>
						<Row className="p-3 mx-0 g-0">
							<Col lg={6}>
								<ImageSlider imageDataArr={property?.photos} height={"100%"} />
							</Col>
							<Col
								className="ps-lg-4 text-dark-bg position-relative"
								style={{ minHeight: "100%" }}
								lg={6}
							>
								<div
									style={{ fontSize: "90%" }}
									className="d-flex align-items-center text-med-grey pb-2 justify-content-between mt-4 mt-lg-0"
								>
									<p className="small-text d-flex align-items-center pb-0 mb-0 text-primary small-text montserrat-semibold">
										<FaMapMarkerAlt className="me-2 text-green" />
										{selectedLocation?.split(",")[0] || "London"} -{" "}
										{(
											getDistanceFromLatLonInKm(
												distancePoint?.latitude,
												distancePoint?.longitude,
												property?.locationLatitude,
												property?.locationLongitude
											) * 0.621371
										).toFixed(2)}{" "}
										mi
									</p>
									<>
										{cart?.filter((e) => e.airtableId === property?.airtableId)
											.length > 0 && (
											<button
												onClick={(e) =>
													handleHeartClick(e, property, OnRemoveFromCart)
												}
												// OnRemoveFromCart(property)}
												className="fs-5 text-green p-0 border-0 bg-transparent"
											>
												<StaticImage
													src={"../images/heart.svg"}
													alt="Your Office People"
													height={24}
													layout="fixed"
												/>
											</button>
										)}
										{cart?.filter((e) => e.airtableId === property?.airtableId)
											.length < 1 && (
											<>
												<button
													onClick={(e) =>
														handleHeartClick(e, property, OnAddToCart)
													}
													// OnRemoveFromCart(property)}
													className="fs-5 text-green p-0 border-0 bg-transparent"
												>
													<StaticImage
														src={"../images/heart-outline.svg"}
														alt="Your Office People"
														height={24}
														layout="fixed"
													/>
												</button>
											</>
										)}
									</>
								</div>

								<h5 className="py-1">{property?.name}</h5>
								{createSlug(property?.city).toLowerCase() === "london" && (
									<div>
										{property?.subwayStation1 !== null && (
											<div
												style={{ fontSize: "90%" }}
												className="d-flex align-items-start pb-2"
											>
												<FaTrain className="me-2 mt-1 text-green" />
												<p className="small-text d-flex align-items-center pb-0 mb-0">
													<span className="montserrat-bold me-1 text-dark-b">
														{property?.subwayStation1}
													</span>{" "}
													-{" "}
													{(
														property?.subwayStation1DistanceKm * 0.621371
													).toFixed(2)}
													mi{" "}
													<span className="d-none d-lg-inline-block ms-1">
														({property?.subwayStation1DurationMins} walk)
													</span>
												</p>
											</div>
										)}
									</div>
								)}

								<blockquote className="mb-2">
									{property?.type === "Serviced Office" &&
										budgetPerDesk?.perDesk && (
											<div className="d-flex justify-content-between align-items-center">
												<p className="mb-0 small-text">Price (from):</p>
												<h5 className="m-0 fs-5">
													£
													{`${
														property?.rentPM === null ||
														property?.rentPM === undefined ||
														property?.rentPM === 0 ||
														property?.rentPM === 0.0
															? "POA"
															: `${round5(property?.rentPM).toLocaleString(
																	"en-UK"
															  )}pcm`
													}`}
												</h5>
											</div>
										)}
									{property?.type === "Serviced Office" &&
										!budgetPerDesk?.perDesk && (
											<div className="d-flex justify-content-between align-items-center">
												<p className="mb-0 small-text">Price (from):</p>
												<h5 className="m-0 fs-5">
													£
													{`${
														property?.rentPM === null ||
														property?.rentPM === undefined ||
														property?.rentPM === 0 ||
														property?.rentPM === 0.0
															? "POA"
															: `${round5(
																	property?.rentPM * lowerCapacity
															  ).toLocaleString("en-UK")}pcm`
													}`}
												</h5>
											</div>
										)}
									{(property?.type === "Managed Office" ||
										property?.type === "Flex Lease") && (
										<div className="d-flex justify-content-between align-items-center">
											<p className="mb-0 small-text">Price (from):</p>
											<h5 className="m-0 fs-5">
												£
												{`${
													floors[0]?.rentPM === null ||
													floors[0]?.rentPM === undefined ||
													floors[0]?.rentPM === 0 ||
													floors[0]?.rentPM === 0.0
														? "POA"
														: `${round5(floors[0]?.rentPM).toLocaleString(
																"en-UK"
														  )}pcm`
												}`}
											</h5>
										</div>
									)}
								</blockquote>

								<Row className="pb-0 pt-0 my-2 align-items-center">
									<CardPillTagSection
										url={`/listings/${slugify(property?.name)}-${
											property?.fixedId
										}`}
										features={property?.features}
									/>
								</Row>
								<div className="w-100" style={{ height: "42px" }}></div>
								<div className="w-100 position-absolute start-0 bottom-0 ps-lg-4">
									<Button
										href={`/listings/${slugify(property?.name)}-${
											property?.fixedId
										}`}
										className={`w-100 fw-bold `}
										variant="secondary"
									>
										View details
									</Button>
								</div>
							</Col>
						</Row>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default FavouriteCard;
