import { ValidationError, useForm } from "@formspree/react";
import freeEmailDomains from "free-email-domains"; // Import the list of free email domains
import "moment/locale/en-gb";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "react-datetime/css/react-datetime.css";
import { v4 as uuidv4 } from "uuid";
import CartContext from "../context/CartContext";

function GetInTouchForm({ officeName, subject }) {
	const { numberOfPeople } = React.useContext(CartContext);
	const [state, handleSubmit] = useForm("xkgnnvor");

	const uniqueId = uuidv4();

	// Initialize maxDesksRequired with numberOfPeople.max if available
	const [maxDesksRequired, setMaxDesksRequired] = useState(
		numberOfPeople && !numberOfPeople.reset ? numberOfPeople.max : ""
	);

	const [emailError, setEmailError] = useState("");

	const formGroupStyles = "mb-2 mb-xl-4";
	const formInputStyles = " border-input";

	useEffect(() => {
		if (state.succeeded) {
			window.location.href = "/thank-you";
		}
	}, [state.succeeded]);

	const onSubmit = (event) => {
		event.preventDefault();
		let hasError = false;

		// Validate Work Email
		const workEmail = event.target.elements["Work email"].value;
		const emailDomain = workEmail.split("@")[1]?.toLowerCase();

		if (
			!emailDomain ||
			freeEmailDomains.includes(emailDomain) ||
			emailDomain.split(".").length < 2
		) {
			setEmailError("Please enter a valid work email address");
			hasError = true;
		} else {
			setEmailError("");
		}

		if (hasError) {
			return;
		}

		handleSubmit(event);
	};

	return (
		<Form className="w-100" onSubmit={onSubmit}>
			<Form.Group className={`${formGroupStyles}`} controlId="Full name">
				<Form.Label className="houschka">Full name *</Form.Label>
				<Form.Control
					required
					name="Full name"
					placeholder="Adam Krill"
					type="text"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError
				prefix="Full name"
				field="Full name"
				errors={state.errors}
			/>

			<Form.Group className={`${formGroupStyles}`} controlId="Work email">
				<Form.Label className="houschka">Work email *</Form.Label>
				<Form.Control
					required
					name="Work email"
					type="email"
					placeholder="hello@yourofficepeople.co.uk"
					className={`${formInputStyles}`}
				/>
				{emailError && (
					<div style={{ color: "red", marginTop: "5px" }}>{emailError}</div>
				)}
			</Form.Group>
			<ValidationError
				prefix="Work email"
				field="Work email"
				errors={state.errors}
			/>
			<Form.Group className={`${formGroupStyles}`} controlId="Phone">
				<Form.Label className="houschka">Phone *</Form.Label>
				<Form.Control
					required
					type="tel"
					name="Phone"
					placeholder="+44 203 016 0270"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Phone" field="Phone" errors={state.errors} />

			<Form.Group className={`${formGroupStyles}`} controlId="Message">
				<Form.Label className="houschka">Message *</Form.Label>
				<Form.Control
					style={{ border: "1px solid #E3E3E3" }}
					required
					as="textarea"
					rows={3}
					name="Message"
					placeholder="Type your message here"
					className={``}
				/>
			</Form.Group>
			<ValidationError prefix="Message" field="Message" errors={state.errors} />

			<Form.Group className="mb-3" controlId="accept-the-policy">
				<Form.Check
					required
					label={
						<span>
							Upon submitting my message, I accept the{" "}
							<a
								className="text-decoration-underline"
								target="_blank"
								rel="noreferrer"
								href="/policies/privacy-notice"
							>
								privacy notice
							</a>
						</span>
					}
					className="w-auto"
					type="checkbox"
					name="accept-the-policy"
					value="true"
				/>
			</Form.Group>
			<ValidationError
				prefix="accept-the-policy"
				field="accept-the-policy"
				errors={state.errors}
			/>

			<Button
				variant="secondary"
				className="px-3 py-2 w-100"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Submit
			</Button>
		</Form>
	);
}

export default GetInTouchForm;
