import { BgImage } from "gbimage-bridge";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import GetInTouchForm from "./get-in-touch-form";
import { SafeHtmlParser } from "./safe-html-parser";

const GetInTouch = ({
	heading,
	body,
	contacts,
	teamMembers,
	backgroundColour,
}) => {
	return (
		<section
			className="get-in-touch py-5 py-lg-7"
			style={{ backgroundColor: backgroundColour || "white" }}
		>
			<Container>
				<Row className="mb-4 justify-content-between">
					<Col lg={6}>
						<h2 className="display-5">{heading}</h2>
						<SafeHtmlParser htmlContent={body} />
						<Row>
							{teamMembers.map((member, index) => (
								<Col
									xs={6}
									key={index}
									className="mb-4 d-flex justify-content-center"
								>
									<BgImage
										style={{
											height: "200px",
											borderRadius: "12px",
											overflow: "hidden",
										}}
										className="w-100 d-flex align-items-end  position-relative"
										image={member.teamMemberFields.featuredImage.gatsbyImage}
									>
										<div
											style={{
												zIndex: 0,
												background:
													"linear-gradient(211deg, rgba(0, 0, 0, 0.00) 58.69%, #1D1D1B 100.08%)",
											}}
											className="position-absolute h-100 w-100"
										></div>
										<div
											style={{ zIndex: 1 }}
											className="d-block position-relative p-2 p-md-3"
										>
											<h4 className="text-white fs-6 mb-0 pb-1">
												{member.teamMemberFields.name}
											</h4>
											<p
												style={{ fontSize: "90%" }}
												className="text-white pb-0 mb-0"
											>
												{member.teamMemberFields.titlerole}
											</p>
										</div>
									</BgImage>
								</Col>
							))}
						</Row>
					</Col>
					<Col lg={5}>
						<div
							className="p-4 mt-4 text-start"
							style={{
								borderRadius: "12px",
								border: "1px solid rgba(149, 193, 31, 0.24)",
								background: "white",
							}}
						>
							<GetInTouchForm />
						</div>
					</Col>
				</Row>
				<Row className="mt-3 g-4 justify-content-between">
					{contacts.map((contact, index) => (
						<Col md={4} key={index} className="text-center">
							<img src={contact.icon.sourceUrl} />
							<h5 className="mt-2 mb-0 pb-1">{contact.heading}</h5>
							<a href={contact.link.url}>{contact.link.title}</a>
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};

export default GetInTouch;
