import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const ImageSliderNew = ({ imageDataArr, height }) => {
	const imagesArray =
		typeof imageDataArr === "string" ? imageDataArr.split(",") : imageDataArr;

	const [currentIndex, setCurrentIndex] = useState(0);
	const [thumbnailOffset, setThumbnailOffset] = useState(0);
	const [maxThumbnailsVisible, setMaxThumbnailsVisible] = useState(5);

	useEffect(() => {
		// Check if window is defined
		if (typeof window !== "undefined") {
			const updateMaxThumbnailsVisible = () => {
				// Set max thumbnails based on screen width
				setMaxThumbnailsVisible(window.innerWidth < 768 ? 3 : 5);
			};

			// Set initial value
			updateMaxThumbnailsVisible();

			// Add event listener to update on resize
			window.addEventListener("resize", updateMaxThumbnailsVisible);

			// Cleanup event listener on component unmount
			return () =>
				window.removeEventListener("resize", updateMaxThumbnailsVisible);
		}
	}, []); // Run only once on component mount

	const handleSelect = (selectedIndex) => {
		setCurrentIndex(selectedIndex);

		if (selectedIndex >= thumbnailOffset + maxThumbnailsVisible) {
			setThumbnailOffset(selectedIndex - maxThumbnailsVisible + 1);
		} else if (selectedIndex < thumbnailOffset) {
			setThumbnailOffset(selectedIndex);
		}
	};

	const goToNext = () => {
		let newIndex = currentIndex + 1;
		if (newIndex >= imagesArray.length) newIndex = 0;
		handleSelect(newIndex);
	};

	const goToPrev = () => {
		let newIndex = currentIndex - 1;
		if (newIndex < 0) newIndex = imagesArray.length - 1;
		handleSelect(newIndex);
	};

	if (!imagesArray || imagesArray.length === 0) {
		console.error("No valid image data found.");
		return <div>No images available</div>;
	}

	return (
		<div className="image-slider-new__container">
			<div className="image-slider-new__wrapper">
				<Carousel
					touch
					activeIndex={currentIndex}
					onSelect={handleSelect}
					interval={null}
					indicators={false}
					controls={false}
					style={{ overflow: "hidden" }}
				>
					{imagesArray?.map((imageSrc, idx) => (
						<Carousel.Item key={idx}>
							<img
								src={imageSrc}
								alt={`Slide ${idx + 1}`}
								className="d-block w-100 carousal-new-img-height"
								style={{
									objectFit: "cover",
								}}
							/>
						</Carousel.Item>
					))}
				</Carousel>

				{/* Thumbnails overlayed at the bottom of the carousel */}
				<div className="image-slider-new__thumbnail-overlay">
					<div className="image-slider-new__thumbnails-wrapper">
						{imagesArray
							?.slice(thumbnailOffset, thumbnailOffset + maxThumbnailsVisible)
							.map((imageSrc, idx) => (
								<div
									key={thumbnailOffset + idx}
									onClick={() => handleSelect(thumbnailOffset + idx)}
									className="image-slider-new__thumbnail"
									style={{
										border:
											currentIndex === thumbnailOffset + idx
												? "2px solid white"
												: "none",
									}}
								>
									<img
										src={imageSrc}
										alt={`Thumbnail ${thumbnailOffset + idx + 1}`}
										className="image-slider-new__thumbnail-image"
									/>
								</div>
							))}
					</div>
				</div>

				{/* Custom Buttons Wrapped in Flexbox */}
				<div className="image-slider-new__buttons-wrapper d-none d-md-block">
					<button
						className="image-slider-new__prev-button white-gradient d-flex align-items-center justify-content-center"
						onClick={goToPrev}
					>
						<div>
							<FaArrowLeftLong className="fs-6" />
						</div>
					</button>

					<button
						className="image-slider-new__next-button white-gradient d-flex align-items-center justify-content-center"
						onClick={goToNext}
					>
						<div>
							<FaArrowRightLong className="fs-6" />
						</div>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ImageSliderNew;
