import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useState } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import BlogBanner from "../components/blog-banner";
import BlogCta from "../components/blog-cta";
import CustomerReviews01 from "../components/CustomerReviews01";
import Layout from "../components/layout";
import { SafeHtmlParser } from "../components/safe-html-parser";

const BlogPostTemplate = ({ data: { post, site, ctaBg, allWpReview } }) => {
	const [email, setEmail] = useState("");
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY;
	const [toast, setToast] = useState({ show: false, message: "", bg: "" });

	const handleSubmit = (event) => {
		event.preventDefault();
		fetch("/.netlify/functions/subscribe", {
			method: "POST",
			body: JSON.stringify({ email: email }),
			headers: {
				"Content-Type": "application/json",
				"x-api-key": apiKey,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message !== "Error subscribing to list.") {
					setToast({ show: true, message: data.message, bg: "primary" });
				} else
					setToast({
						show: true,
						message: "Error subscribing to list.",
						bg: "danger",
					});
			})
			.catch((error) => {
				setToast({
					show: true,
					message: "Error subscribing to list.",
					bg: "danger",
				});
			});
	};
	const siteUrl = site.siteMetadata.siteUrl;
	const postUrl = `${site.siteMetadata.siteUrl}/blog-page/${post.slug}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blogs`,
					id: `${siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/blogs/${post.slug}`,
					id: `${siteUrl}/blogs/${post.slug}`,
				},
			},
		],
	};

	const { blogCta, customerReviews } = post.blogFields;

	const bannerHeading =
		blogCta?.bannerHeading || "Let us discover your ideal office";
	const bannerBody =
		blogCta?.bannerBody ||
		"With our treasure trove of office spaces, we offer a huge range to suit your business. Whether you're after a flexible, sleek team hub to grow your identity or an elegant private office, we’ve got the space that is right up your street!";
	const bannerButtonTitle =
		blogCta?.bannerButton?.title || "Book a consultation";
	const bannerButtonUrl = blogCta?.bannerButton?.url || "/contact-us";

	const bannerBgImage =
		blogCta?.bannerBackground?.localFile?.childImageSharp ||
		ctaBg?.localFile?.childImageSharp;

	const reviewHeading =
		customerReviews?.heading || "Don’t just take our word for it";
	const reviewBody =
		customerReviews?.body ||
		"Hear from our happy clients what we bring to the office table! From finding the dream space to handling the twists and turns of lease negotiations, our team is all about making your office hunt a breeze.";
	const reviewButtonTitle =
		customerReviews?.link?.title || "Book a consultation";
	const reviewButtonUrl = customerReviews?.link?.url || "/contact-us";

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post?.seoFields?.title}
				description={post?.seoFields?.metaDesc}
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/blog/${post.slug}`,
					title: `${post?.seoFields?.opengraphTitle}`,
					description: `${post?.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${post?.seoFields?.image?.localFile.publicURL}`,
							width: `${post?.seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${post?.seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${post?.seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<BlogBanner
					title={post.title}
					postFields={post.blogFields}
					postUrl={postUrl}
					date={post.date}
				/>
				<section className="mt-5 mt-md-8">
					<Container>
						<Row className="gx-6">
							<Col lg={8} className="blog-default-content">
								{post.content && <SafeHtmlParser htmlContent={post.content} />}
							</Col>
							<Col lg={4} id="subscribe-form">
								<div className="border border-dark-gray rounded-3 p-4">
									<h4 className="mb-0">Don't miss out!</h4>
									<p className="fs-6 text-black py-3 mb-0">
										Sign up for our newsletter and stay ahead of the game about
										all things offices
									</p>
									<Form className="w-100" onSubmit={handleSubmit}>
										<Row className="mb-3">
											<Col lg="12">
												<Form.Control
													type="email"
													placeholder="hello@yourofficepeople.co.uk"
													className="px-3 bg-transparent border-0 rounded-0  border-bottom border-secondary py-2"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
													required
												/>
											</Col>
											<Col lg="12">
												<Button
													type="submit"
													className="w-100 text-primary font-weight-bold w-full mt-4 py-2"
													variant="secondary"
												>
													Subscribe
												</Button>
											</Col>
										</Row>
									</Form>
								</div>
							</Col>
						</Row>

						<BlogCta
							bannerHeading={bannerHeading}
							bannerBody={bannerBody}
							bannerBackground={bannerBgImage}
							bannerButton={{ url: bannerButtonUrl, title: bannerButtonTitle }}
						/>
					</Container>
					<CustomerReviews01
						heading={reviewHeading}
						body={reviewBody}
						reviews={allWpReview.nodes}
						button={{ url: reviewButtonUrl, title: reviewButtonTitle }}
						backgroundColour={"#f3faf3"}
					/>
				</section>
				<ToastContainer
					style={{ position: "fixed", top: 0, right: 0, zIndex: 1050 }}
					position="top-end"
					className="p-3"
				>
					<Toast
						onClose={() => setToast({ ...toast, show: false })}
						show={toast.show}
						delay={3000}
						autohide
						bg={toast.bg}
					>
						<Toast.Header>
							<strong className="me-auto">Notification</strong>
						</Toast.Header>
						<Toast.Body className="text-white">{toast.message}</Toast.Body>
					</Toast>
				</ToastContainer>
			</Layout>
		</>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		ctaBg: wpMediaItem(title: { eq: "about hero" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		allWpReview {
			nodes {
				reviewFields {
					body
					name
				}
				title
				date(formatString: "DD MMMM YY")
			}
		}
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			slug
			content
			seoFields {
				metaDescription
				metaTitle
				opengraphDescription
				opengraphTitle
				productSchema
				image {
					altText
					sourceUrl
					publicUrl
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
					}
					mediaDetails {
						height
						width
					}
				}
			}
			title
			date(formatString: "DD MMMM YYYY")
			blogFields {
				minToRead
				smallHeading
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
				authorTeamMember {
					... on WpTeamMember {
						id
						title
						teamMemberFields {
							featuredImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											quality: 100
											placeholder: BLURRED
											layout: FULL_WIDTH
										)
									}
								}
							}
							name
							titlerole
						}
					}
				}
				blogCta {
					bannerBody
					bannerButton {
						target
						title
						url
					}
					bannerHeading
					bannerBackground {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
								)
							}
						}
					}
				}
				customerReviews {
					fieldGroupName
					heading
					link {
						target
						title
						url
					}
					body
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
