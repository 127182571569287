// src/components/FeatureList02.js

import { Link } from "gatsby";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ListingsPropertyCardNew from "./Listings-property-card-new";
import { SafeHtmlParser } from "./safe-html-parser";

const FeatureList02 = ({ heading, smallHeading, body, properties }) => {
	return (
		<section className="py-5 py-lg-7 bg-green-bg">
			<Container>
				<Row className="justify-content-center">
					<Col lg={9} className="text-center">
						{smallHeading && <h5 className="text-green ">{smallHeading}</h5>}
						{heading && <h2 className=" display-5 mb-3">{heading}</h2>}
						{body && <SafeHtmlParser htmlContent={body} />}
					</Col>
				</Row>

				<Row className="mt-5">
					{properties.map((property, i, a) => (
						<Col xs={12} md={6}>
							<div className="mb-5">
								{" "}
								<ListingsPropertyCardNew property={property} index={i} />
							</div>
						</Col>
					))}
				</Row>
				<Row className="mt-4 text-center">
					<Col>
						<Button as={Link} to="/listings" variant="transparent">
							View more
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default FeatureList02;
