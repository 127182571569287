import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";

const Layout03 = ({ heading, body, image, button }) => {
	return (
		<section className="layout02 py-5 py-sm-8">
			<Container>
				<Row className="align-items-center g-5 g-xl-6">
					<Col
						md={{ span: 6, order: "last" }}
						className="d-flex justify-content-center position-relative"
					>
						{image?.localFile && (
							<GatsbyImage
								image={image?.localFile.childImageSharp.gatsbyImageData}
								alt={image.altText || "Image"}
								className=""
								style={{ borderRadius: "12px" }}
							/>
						)}
					</Col>
					<Col md={6} className="text-center text-md-start mb-4 mb-md-0">
						<h1 className="display-5 mb-3">{heading}</h1>
						<SafeHtmlParser htmlContent={body} />
						{button && (
							<Button
								href={button.url}
								target={button.target}
								variant="secondary"
								className="mt-3 px-4 py-2"
							>
								{button.title}
							</Button>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Layout03;
