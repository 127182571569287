// src/components/ReviewCount.js
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const ReviewCount = () => {
	// GraphQL query to fetch the review count
	const data = useStaticQuery(graphql`
		query {
			wpPage(slug: { eq: "homepage" }) {
				reviewCountFields {
					reviewCount
				}
			}
		}
	`);

	// Extracting the review count from the query result
	const reviewCount = data?.wpPage?.reviewCountFields.reviewCount || 0;

	// Return the count so it can be used by other components
	return <>{reviewCount}</>;
};

export default ReviewCount;
