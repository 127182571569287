import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";
import TeamPopup from "./team-popup";

const OurTeam02 = ({ heading, body, teamMembers }) => {
	const [vidShow, setVidShow] = useState(false);
	const [popupContent, setPopupContent] = useState(null);

	const handleVidShow = (content) => {
		setVidShow(true);
		setPopupContent(content);
	};
	const handleVidClose = () => setVidShow(false);

	return (
		<section className="our-team02 py-5 bg-light">
			<Container>
				<Row className="mb-4 text-center justify-content-center">
					<Col lg={7}>
						<h2 className="text-center display-5 mb-3">{heading}</h2>
						<SafeHtmlParser htmlContent={body} />
					</Col>
				</Row>
				<Row>
					{teamMembers.map((member, index) => (
						<Col
							md={6}
							key={index}
							className="mb-4 d-flex justify-content-center"
						>
							<Card
								style={{ borderRadius: "12px", overflow: "hidden" }}
								className="text-center border-0 team-member-card shadow-sm w-100"
							>
								{member.teamMemberFields?.featuredImage && (
									<GatsbyImage
										image={
											member.teamMemberFields.featuredImage.localFile
												?.childImageSharp?.gatsbyImageData
										}
										alt={member.teamMemberFields.name || "Team Member"}
										className="card-img-top"
										imgStyle={{ objectPosition: "top" }}
									/>
								)}
								<Card.Body className="text-start p-4 bg-primary">
									<h4 className="text-white mb-1">
										{member.teamMemberFields.name}
									</h4>
									<p className="text-white mb-3">
										{member.teamMemberFields.titlerole}
									</p>

									<Row>
										<Col className="mt-2 mt-sm-3 d-md-flex justify-content-between">
											<Button
												onClick={() => handleVidShow(member.teamMemberFields)}
												className={`text-white me-md-3 btn w-100 w-md-50 fw-bold `}
												variant="transparent"
											>
												Read bio
											</Button>
											<Button
												as={Link}
												className={`text-primary d-md-none mt-3 mt-md-0 btn border-0 w-100 w-md-50 fw-bold `}
												variant="secondary"
												href={`tel:${member.teamMemberFields.phoneNumbers?.number}`}
											>
												Talk to {member.teamMemberFields.name.split(" ")[0]}
											</Button>
											<Button
												as={Link}
												className={`text-primary d-none d-md-inline-block mt-3 mt-md-0 btn border-0 w-100 w-md-50 fw-bold `}
												variant="secondary"
												href={`mailto:${member.teamMemberFields.email}`}
											>
												Talk to {member.teamMemberFields.name.split(" ")[0]}
											</Button>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
				<Modal
					style={{ zIndex: 9999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
					size="lg"
					dialogClassName="team-modal"
					show={vidShow}
					centered
					onHide={handleVidClose}
					className="w-100 p-2 pe-5 p-xl-4"
				>
					<TeamPopup
						popupContent={popupContent}
						handleVidClose={handleVidClose}
					/>
				</Modal>
			</Container>
		</section>
	);
};

export default OurTeam02;
