import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { jsonrepair } from "jsonrepair";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import GetInTouchForm from "../components/get-in-touch-form";
import Layout from "../components/layout";
import ShortlistPageCard from "../components/shortlist-page-card";
import TeamMemberCardBig from "../components/TeamMemberCardBig";

const slugify = require("slugify");

const ShortlistPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpTeamMember {
				nodes {
					title
					teamMemberFields {
						email
						name
						titlerole
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						phoneNumbers {
							number
						}
					}
				}
			}
			allAirtableProperties(
				filter: {
					isFloor: { nin: 1 }
					hasFeatures: { eq: 1 }
					hasPhotos: { eq: 1 }
					live: { eq: true }
				}
			) {
				edges {
					node {
						addressLineOne
						addressLineTwo
						city
						fixedId
						live
						postCode
						desksFrom
						desksTo
						city
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name
						features
						description
						photos
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const [uniqueId, setUniqueId] = useState("");
	const [bookingEmail, setBookingEmail] = useState("");
	const [bookingProperties, setBookingProperties] = useState("");
	const [idOfProperties, setIdOfProperties] = useState([]);
	const [viewingJsonTemp, setViewingJsonTemp] = useState([]);
	const [viewingJson, setViewingJson] = useState([]);
	const [matchingProperties, setMatchingProperties] = useState([]);
	const [combinedProperties, setCombinedProperties] = useState([]);
	const [commentValues, setCommentValues] = useState([]);
	const [bookingCompany, setBookingCompany] = useState("");
	const [confirmedDate, setConfirmedDate] = useState("");
	const [loading, setLoading] = useState(true);
	const [uniqueIdCheck, setUniqueIdCheck] = useState(true);
	const [activeCard, setActiveCard] = useState(null);
	const [activeDescriptionCard, setActiveDescriptionCard] = useState(null);
	const [accountManager, setAccountManager] = useState(null);

	const handleDescriptionClick = (index) => {
		setActiveDescriptionCard(index);
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			const queryParameters = new URLSearchParams(window.location.search);
			const uid = queryParameters.get("id") || "";
			setUniqueId(uid);

			if (uid === "") {
				setUniqueIdCheck(false);
				setLoading(false);
			}
		}
	}, []);

	const siteUrl = data.site.siteMetadata.siteUrl;

	useEffect(() => {
		if (uniqueId) {
			fetch(`/.netlify/functions/getBookingData?id=${uniqueId}`)
				.then((response) => response.json())
				.then((data) => {
					if (data && data.length > 0) {
						const record = data[0];
						// console.log(record);
						setBookingEmail(record["Email"]);
						setBookingProperties(record["Properties"]);
						setBookingCompany(record["Company name"]);

						const tempId = record["Connected Viewings"] || [];
						setIdOfProperties(tempId);
						const accountManagerEmail = record["Account Manager"]; // Update this to match your actual data structure
						setAccountManager(accountManagerEmail);

						const tempViewing = record["Viewing Json"] || [];
						setViewingJsonTemp(tempViewing);
					} else {
						setUniqueIdCheck(false);
					}
					setLoading(false);
				})
				.catch((error) => {
					console.error("Fetch Error:", error);
					setLoading(false);
				});
		} else {
			setUniqueIdCheck(false);
			setLoading(false);
		}
	}, [uniqueId]);

	useEffect(() => {
		if (viewingJsonTemp?.length > 0) {
			const parsedArray = viewingJsonTemp.map((val) =>
				JSON.parse(jsonrepair(val))
			);
			setViewingJson(parsedArray);
		}
	}, [viewingJsonTemp]);

	useEffect(() => {
		if (viewingJson?.length > 0) {
			const matchedProperties = viewingJson.map(
				(val) =>
					data.allAirtableProperties.edges.find(
						(o) => o.node.airtableId === val.propertyId
					)?.node
			);
			setMatchingProperties(matchedProperties);
		}
	}, [viewingJson]);

	useEffect(() => {
		if (matchingProperties?.length > 0) {
			const combined = viewingJson.map((value, index) => ({
				value,
				...matchingProperties[index],
			}));
			setCombinedProperties(combined);
		}
	}, [matchingProperties]);

	const teamMember =
		data.allWpTeamMember.nodes.find(
			(member) => member.teamMemberFields.name === accountManager
		) || null;

	return (
		<Layout>
			<Helmet></Helmet>
			<GatsbySeo title={`${bookingCompany} Shortlist`} language="en" noindex />
			<div style={{ background: "#F0F1F3" }}>
				<section className=" bg-opacity-10 py-3 py-xl-4  ">
					<Container>
						<Row>
							<Col className="text-center">
								<h1 className="fs-1 pt-lg-5 pt-4">
									{bookingCompany}
									{bookingCompany ? "'s" : ""} Shortlist
								</h1>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-6">
					<Container>
						<Row>
							<Col lg={{ offset: 3, span: 6 }}>
								{idOfProperties.length < 1 && (
									<div
										className={`w-100 position-relative ${
											!loading && !uniqueIdCheck ? "" : "d-none"
										}`}
										style={{ height: "200px" }}
									>
										<span className="position-absolute bottom-0 start-50 translate-middle-x text-center pb-6 pb-lg-0">
											<p className="fs-4">
												No viewings yet. See our{" "}
												<Link to="/listing" className=" fw-bold">
													listings
												</Link>{" "}
												to book your first viewings
											</p>
										</span>
									</div>
								)}
								<div
									className={`w-100 position-relative ${
										loading ? "" : "d-none"
									}`}
									style={{ height: "200px" }}
								>
									<span className="position-absolute bottom-0 start-50 translate-middle-x text-center pb-6 pb-lg">
										<Spinner animation="border" variant="primary" />
										<p>Properties loading...</p>
									</span>
								</div>
							</Col>
						</Row>
						<Row className="h-100 g-4 mb-4">
							{combinedProperties.map((viewing, i) => (
								<Col md={6}>
									<ShortlistPageCard
										documentLink={viewing.value.document}
										date={
											`${
												viewing.value.confirmedDate === ""
													? "TBC"
													: `${moment(
															new Date(viewing.value.confirmedDate)
													  ).format("DD MMMM")}`
											} ${
												viewing.value.confirmedDate === ""
													? "TBC"
													: `${moment(
															new Date(viewing.value.confirmedDate)
													  ).format("HH:mm")}`
											}` || ""
										}
										property={viewing}
										index={i}
									/>
								</Col>
							))}
						</Row>
					</Container>
				</section>
			</div>
			<section className="py-5 py-lg-7" style={{ background: "#FBFBFB" }}>
				<Container>
					<Row className="justify-content-between g-5 g-lg-6">
						<Col md={{ span: 6, order: "last" }} lg={5}>
							<div
								className="p-4 text-start"
								style={{
									borderRadius: "12px",

									background: "white",
								}}
							>
								<GetInTouchForm />
							</div>
						</Col>
						<Col md={6}>
							<h2 className="fs-2">Meet your office game changer</h2>
							<p className="mb-4">
								With our expertise, resilience, and personal touch, we'll guide
								you through the process and help you secure the office that’s
								just right for you!
							</p>
							<Row>
								<Col lg={8} xl={7} xxl={6}>
									{teamMember !== null && (
										<TeamMemberCardBig teamMember={teamMember} />
									)}
								</Col>
							</Row>
						</Col>{" "}
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default ShortlistPage;
