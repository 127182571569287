import { BgImage } from "gbimage-bridge";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const Cta = ({ heading, body, button, backgroundImage }) => {
	return (
		<section className="cta-section py-5">
			<BgImage
				image={backgroundImage?.gatsbyImage}
				className="cta-section__background"
			>
				<Container className="position-relative text-center text-white py-5">
					<Row className="justify-content-center">
						<Col lg={8}>
							<h2 className="display-4 mb-3">{heading}</h2>
							<p className="lead mb-4">{body}</p>
							{button && (
								<Button
									href={button.url}
									target={button.target}
									variant="light"
								>
									{button.title}
								</Button>
							)}
						</Col>
					</Row>
				</Container>
				<div className="cta-section__overlay position-absolute w-100 h-100 top-0 start-0 bg-dark opacity-50"></div>
			</BgImage>
		</section>
	);
};

export default Cta;
