import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";

const OurTeam01 = ({
	bannerHeading,
	bannerBody,
	bannerBackground,
	bannerButton,
}) => {
	const image = bannerBackground ? getImage(bannerBackground) : null; // Prepare the image data for BgImage

	const backgroundStyle = {
		marginTop: 0,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		minHeight: "390px",
		borderRadius: "12px",
		overflow: "hidden",
	};
	const overlayStyle = {
		background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)`,
		backgroundBlendMode: "multiply",
		zIndex: 1,
	};

	return (
		<section className="blog-cta py-5 py-md-8 position-relative">
			<Container>
				<BgImage
					image={image}
					className="d-flex align-items-center position-relative"
					style={backgroundStyle}
				>
					<div
						className="position-absolute start-0 top-0 h-100 w-100 d-flex justify-content-center rounded-3"
						style={overlayStyle}
					></div>
					<Container
						style={{ zIndex: 2 }}
						className="position-relative h-100 w-100 d-flex justify-content-center align-items-center"
					>
						<Row className={`justify-content-center py-4 w-100 h-100`}>
							<Col md={9} xl={7} className={`mb-md-4 mb-lg-0 text-center`}>
								<h1 className={`my-auto text-white pb-0 display-6`}>
									{bannerHeading}
								</h1>

								{bannerBody && (
									<p className="text-white pt-3">
										<SafeHtmlParser htmlContent={bannerBody} />
									</p>
								)}

								<div className="d-flex align-items-center justify-content-center flex-column flex-md-row">
									{bannerButton && bannerButton.url && (
										<Button
											as={Link}
											href={bannerButton.url}
											size="lg"
											className="px-4 py-2 fs-6 border-0 w-100 w-md-auto mt-1"
											target={bannerButton.target}
											variant="secondary"
										>
											{bannerButton.title}
										</Button>
									)}
								</div>
							</Col>
						</Row>
					</Container>
				</BgImage>
			</Container>
		</section>
	);
};

export default OurTeam01;
