import { marked } from "marked";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";
const PropertyDescription = ({ property }) => {
	const {
		name,
		description,
		features,
		amenities,
		services,
		addressLineOne,
		addressLineTwo,
		city,
		postCode,
	} = property;

	return (
		<section className="py-5 py-lg-7 bg-primary">
			<Container>
				<Row>
					<Col className="text-white">
						<SafeHtmlParser htmlContent={marked(description || "none")} />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PropertyDescription;
