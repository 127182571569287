import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";

const TeamMemberCardBig = ({ teamMember }) => {
	const { name, email, phoneNumbers, featuredImage, titlerole } =
		teamMember?.teamMemberFields || {};

	// Get the GatsbyImage data
	const image = getImage(
		featuredImage?.localFile?.childImageSharp?.gatsbyImageData
	);

	return (
		<div className="p-3" style={styles.cardContainer}>
			{/* Team Member Image */}
			<div style={styles.imageContainer}>
				<GatsbyImage
					image={image}
					alt={featuredImage.altText}
					style={styles.circularImage}
					imgStyle={styles.imageStyle} // Custom style for inner image
				/>
			</div>

			{/* Team Member Details */}
			<div className="w-100" style={styles.detailsContainer}>
				<h4 style={styles.name}>{name}</h4>
				<p style={styles.position}>{titlerole}</p> {/* Position text */}
				{/* Phone Number */}
				<div className="text-start w-100">
					{phoneNumbers?.[0]?.number && (
						<div style={styles.contactItem}>
							<FaPhone style={styles.icon} />
							<a href={`tel:${phoneNumbers[0].number}`} style={styles.link}>
								{phoneNumbers[0].number}
							</a>
						</div>
					)}
					{/* Email */}
					{email && (
						<div style={styles.contactItem}>
							<FaEnvelope style={styles.icon} />
							<a href={`mailto:${email}`} style={styles.link}>
								{email}
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

// Styles
const styles = {
	cardContainer: {
		borderRadius: "12px",
		border: "0px", // Light border for the card
		backgroundColor: "#fff",
		// Subtle shadow for depth
		maxWidth: "100%", // Set a fixed width for the card
		margin: "10px auto", // Center the card horizontally
	},

	imageContainer: {
		marginBottom: "15px", // Space between the image and text
	},
	circularImage: {
		width: "100%",
		borderRadius: "8px",
		overflow: "hidden",
		objectFit: "cover",
	},
	imageStyle: {
		borderRadius: "8px", // Ensure the inner image is circular
	},
	detailsContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	name: {
		margin: "0",
		color: "#333",
		fontSize: "18px",
		fontWeight: "bold",
	},
	position: {
		margin: "5px 0",
		fontSize: "14px",
		color: "#3AAA35", // Green color for the position text
	},
	contactItem: {
		display: "flex",
		alignItems: "start",
		marginTop: "8px",
	},
	icon: {
		color: "#3AAA35", // Green icon color
		marginRight: "8px",
	},
	link: {
		color: "#333",
		textDecoration: "none",
		fontSize: "14px",
	},
};

export default TeamMemberCardBig;
