import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import Areas from "../components/Areas";
import BookingForm from "../components/BookingForm";
import CustomerReview01 from "../components/CustomerReviews01";
import FeatureList01 from "../components/FeatureList01";
import HeaderLayout01 from "../components/HeaderLayout01";
import Layout from "../components/layout";
import Layout01 from "../components/Layout01";
import OurTeam01 from "../components/OurTeam01";
import ProcessStep from "../components/ProcessSteps";
import SocialMediaPosts from "../components/SocialMediaPosts";
import TrustedBy from "../components/TrustedBy";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			allAirtableProperties(
				filter: {
					isFloor: { nin: 1 }
					hasFeatures: { eq: 1 }
					hasPhotos: { eq: 1 }
					live: { eq: true }
				}
			) {
				edges {
					node {
						addressLineOne
						desksFrom
						desksTo
						live
						fixedId
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name
						provider
						city
						features
						description
						photos
					}
				}
			}
			pageData: wpPage(slug: { eq: "homepage" }) {
				seoFields {
					metaTitle
					metaDescription
					opengraphDescription
					opengraphTitle
					productSchema
					image {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
							publicURL
						}
					}
				}
				pageFields {
					components {
						... on WpPage_Pagefields_Components_HeaderLayout01 {
							body
							fieldGroupName
							heading
							imageOrVideo
							backgroundVideo {
								altText
								sourceUrl
								mediaItemUrl
							}
							backgroundImage {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							button {
								target
								title
								url
							}
						}

						... on WpPage_Pagefields_Components_BookingForm {
							body
							fieldGroupName
							heading
							backgroundImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpPage_Pagefields_Components_TrustedBy {
							fieldGroupName
							heading
							logos {
								image {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						... on WpPage_Pagefields_Components_Layout01 {
							body
							fieldGroupName
							heading
							smallHeading
							button {
								target
								title
								url
							}
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						... on WpPage_Pagefields_Components_FeatureList01 {
							body
							fieldGroupName
							button {
								target
								title
								url
							}
							heading
							smallHeading
							propertyIds {
								fieldGroupName
								id
							}
						}
						... on WpPage_Pagefields_Components_OurTeam01 {
							bannerBody
							bannerHeading
							bannerBackground {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							heading
							teamMember {
								... on WpTeamMember {
									id
									teamMemberFields {
										name
										body
										featuredImage {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
										email
										images {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
										phoneNumbers {
											number
										}
										titlerole
									}
								}
							}
							fieldGroupName
							body
							bannerButton {
								target
								url
								title
							}
						}
						... on WpPage_Pagefields_Components_ProcessSteps {
							body
							fieldGroupName
							button {
								target
								title
								url
							}
							heading
							step1 {
								body
								fieldGroupName
								heading
								icon {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							step2 {
								body
								heading
								icon {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							step3 {
								body
								heading
								icon {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						... on WpPage_Pagefields_Components_CustomerReviews01 {
							backgroundColour
							body
							button {
								target
								title
								url
							}
							fieldGroupName
							heading
							reviews {
								... on WpReview {
									id
									reviewFields {
										body
										name
									}
									title
								}
							}
						}
						... on WpPage_Pagefields_Components_Areas {
							body
							fieldGroupName
							heading
							button {
								target
								title
								url
							}
							button2 {
								target
								title
								url
							}
							popularLocations {
								... on WpPopularLocation {
									id
									popularLocationsFields {
										url
										featuredImage {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
									title
									slug
								}
							}
						}
						... on WpPage_Pagefields_Components_SocialMediaPosts {
							backgroundColour
							body
							fieldGroupName
							heading
							secondHeading
							socialLinks {
								url
								icon {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							socialPosts {
								body
								fieldGroupName
								image {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: {
			slug,
			seoFields,
			pageFields: { components },
		},
		allAirtableProperties,
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.metaTitle}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.localFile.publicURL}`,
							width: `${seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{components &&
					components.length > 0 &&
					components.map((sectionInfo) => (
						<>
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_HeaderLayout01" && (
								<HeaderLayout01
									heading={sectionInfo?.heading}
									body={sectionInfo?.body}
									backgroundImage={
										sectionInfo.backgroundImage?.localFile.childImageSharp
											.gatsbyImageData
									}
									backgroundVideo={sectionInfo?.backgroundVideo}
									button={sectionInfo?.button}
									imageOrVideo={sectionInfo?.imageOrVideo}
								/>
							)}
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_TrustedBy" && (
								<TrustedBy
									heading={sectionInfo?.heading}
									logos={sectionInfo?.logos}
								/>
							)}
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_Layout01" && (
								<Layout01
									heading={sectionInfo?.heading}
									smallHeading={sectionInfo?.smallHeading}
									body={sectionInfo?.body}
									image={sectionInfo?.image}
									button={sectionInfo?.button}
								/>
							)}
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_FeatureList01" && (
								<FeatureList01
									heading={sectionInfo?.heading}
									smallHeading={sectionInfo?.smallHeading}
									body={sectionInfo?.body}
									propertyIds={sectionInfo?.propertyIds}
									button={sectionInfo?.button}
									allAirtableProperties={allAirtableProperties}
								/>
							)}
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_OurTeam01" && (
								<OurTeam01
									bannerHeading={sectionInfo?.bannerHeading}
									bannerBody={sectionInfo?.bannerBody}
									bannerButton={sectionInfo?.bannerButton}
									bannerBackground={
										sectionInfo?.bannerBackground?.localFile?.childImageSharp
									}
									heading={sectionInfo?.heading}
									body={sectionInfo?.body}
									teamMember={sectionInfo?.teamMember}
								/>
							)}
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_ProcessSteps" && (
								<ProcessStep
									heading={sectionInfo?.heading}
									body={sectionInfo?.body}
									button={sectionInfo?.button}
									step1={sectionInfo?.step1}
									step2={sectionInfo?.step2}
									step3={sectionInfo?.step3}
								/>
							)}

							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_CustomerReviews01" && (
								<CustomerReview01
									heading={sectionInfo?.heading}
									body={sectionInfo?.body}
									button={sectionInfo?.button}
									backgroundColour={sectionInfo?.backgroundColour}
									reviews={sectionInfo?.reviews}
								/>
							)}
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_Areas" && (
								<Areas
									heading={sectionInfo?.heading}
									body={sectionInfo?.body}
									button={sectionInfo?.button}
									button2={sectionInfo?.button2}
									popularLocations={sectionInfo?.popularLocations}
								/>
							)}

							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_BookingForm" && (
								<BookingForm
									heading={sectionInfo?.heading}
									body={sectionInfo?.body}
									backgroundImage={sectionInfo?.backgroundImage}
								/>
							)}
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_SocialMediaPosts" && (
								<SocialMediaPosts
									heading={sectionInfo?.heading}
									secondHeading={sectionInfo?.secondHeading}
									body={sectionInfo?.body}
									socialLinks={sectionInfo?.socialLinks}
									socialPosts={sectionInfo?.socialPosts}
									backgroundColour={sectionInfo?.backgroundColour}
								/>
							)}
						</>
					))}
			</Layout>
		</>
	);
};

export default IndexPage;
