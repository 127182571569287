import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

// Import components
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Areas from "../components/Areas";
import BlogCatalogue from "../components/BlogCatalogue";
import BookingForm from "../components/BookingForm";
import Cta from "../components/Cta";
import CustomerReviews01 from "../components/CustomerReviews01";
import CustomerReviews02 from "../components/CustomerReviews02";
import ExpertConsultation from "../components/ExpertConsultation";
import FeatureList01 from "../components/FeatureList01";
import FeatureList02 from "../components/FeatureList02";
import GetInTouch from "../components/GetInTouch";
import HeaderLayout01 from "../components/HeaderLayout01";
import Layout01 from "../components/Layout01";
import Layout02 from "../components/Layout02";
import OurTeam01 from "../components/OurTeam01";
import OurTeam02 from "../components/OurTeam02";
import ProcessSteps from "../components/ProcessSteps";
import PropertyDescription from "../components/PropertyDescription";
import PropertyDetailHero from "../components/PropertyDetailHero";
import PropertyMap from "../components/PropertyMap";
import SocialMediaPosts from "../components/SocialMediaPosts";
import TrustedBy from "../components/TrustedBy";
import VideoIntro from "../components/VideoIntro";
import CartContext from "../context/CartContext";
const slugify = require("slugify");

const PropertyDetailsTemplate = ({
	data: { wpPage, property, allWpTeamMember, site },
}) => {
	const {
		cart,
		viewport,
		numberOfPeople,
		OnAddToCart,
		OnRemoveFromCart,
		budgetPerDesk,
		onLocationChangeScroll,
		onLocationChangePan,
		shortOffices,
		getDistanceFromLatLonInKm,
		settingVal,
		onLocationChange,
		filteredOffices,
		everyOffice,
	} = React.useContext(CartContext);
	const siteUrl = site?.siteMetadata?.siteUrl;
	const [nearbyProperties, setNearbyProperties] = useState([]);
	const assignedTeamMember =
		allWpTeamMember.nodes.find(
			(teamMember) =>
				teamMember.teamMemberFields.name === property.assignedTeamMember
		) ||
		allWpTeamMember.nodes.find(
			(teamMember) => teamMember.teamMemberFields.name === "Nancy Stergiopoulos"
		);
	useEffect(() => {
		// Move map (if needed)
		if (typeof window !== "undefined") {
			onLocationChange(property?.locationLatitude, property?.locationLongitude);

			settingVal(
				property.addressLineOne.split(",").slice(0, 1).join(","),
				"locationValue"
			);
			onLocationChangeScroll(
				property?.locationLatitude,
				property?.locationLongitude
			);
		}

		// Compute nearest offices based on lat/lon
		if (
			property?.locationLatitude &&
			property?.locationLongitude &&
			everyOffice?.length
		) {
			const { locationLatitude, locationLongitude, fixedId } = property;

			// 1) For each property, compute distance from current property
			const withDistances = everyOffice.map((edge) => {
				const dist = getDistanceFromLatLonInKm(
					locationLatitude,
					locationLongitude,
					edge.node.locationLatitude,
					edge.node.locationLongitude
				);
				return {
					...edge,
					distance: dist,
				};
			});

			// 2) Filter out the exact same property
			const filtered = withDistances.filter(
				(edge) => edge.node.fixedId !== fixedId
			);

			// 3) Sort ascending by distance
			filtered.sort((a, b) => a.distance - b.distance);

			// 4) Take top 4 or however many you want
			const nearest = filtered.slice(0, 4);
			setNearbyProperties(nearest);
		}
	}, [property]);

	const createSlug = (value) => {
		// Ensure the value is a string or provide a default empty string if it's not
		const stringValue = value ? String(value) : "";
		return slugify(stringValue);
	};

	const renderListingTemplateComponent = (component, index) => {
		switch (component.fieldGroupName) {
			case "ListingsTemplate_Listingstemplatefields_ListingsTemplateComponents_PropertyDescription":
				return (
					<PropertyDescription
						key={index}
						property={property} // Pass in the property data
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_ListingsTemplateComponents_PropertyMap":
				return (
					<PropertyMap
						teamMember={assignedTeamMember}
						key={index}
						property={property} // Pass in the property data
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_ListingsTemplateComponents_FeatureList02":
				return (
					<FeatureList02
						key={index}
						body={component.body}
						heading={component.heading}
						smallHeading={component.smallHeading}
						properties={nearbyProperties} // Pass in the property data if needed
					/>
				);
			default:
				return <div key={index}></div>;
		}
	};
	const renderComponent = (component, index) => {
		switch (component.fieldGroupName) {
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_VideoIntro":
				return (
					<VideoIntro
						key={index}
						body={component.body}
						heading={component.heading}
						imageOrVideo={component.imageOrVideo}
						button={component.button}
						backgroundImage={component.backgroundImage}
						backgroundVideo={component.backgroundVideo}
					/>
				);

			case "ListingsTemplate_Listingstemplatefields_OtherComponents_TrustedBy":
				return (
					<TrustedBy
						key={index}
						heading={component.heading}
						logos={component.logos}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_HeaderLayout01":
				return (
					<HeaderLayout01
						key={index}
						heading={component.heading}
						body={component.body}
						imageOrVideo={component.imageOrVideo}
						backgroundImage={component.backgroundImage}
						button={component.button}
						backgroundVideo={component.backgroundVideo}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_Layout01":
				return (
					<Layout01
						key={index}
						heading={component.heading}
						smallHeading={component.smallHeading}
						body={component.body}
						image={component.image}
						button={component.button}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_Layout02":
				return (
					<Layout02
						key={index}
						heading={component.heading}
						body={component.body}
						image={component.image}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_FeatureList01":
				return (
					<FeatureList01
						key={index}
						heading={component.heading}
						smallHeading={component.smallHeading}
						body={component.body}
						propertyIds={component.propertyIds}
						button={component.button}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_OurTeam01":
				return (
					<OurTeam01
						key={index}
						bannerHeading={component.bannerHeading}
						bannerBody={component.bannerBody}
						heading={component.heading}
						body={component.body}
						teamMember={component.teamMember}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_OurTeam02":
				return (
					<OurTeam02
						key={index}
						heading={component.heading}
						body={component.body}
						teamMembers={component.teamMembers}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_ProcessSteps":
				return (
					<ProcessSteps
						key={index}
						heading={component.heading}
						body={component.body}
						button={component.button}
						step1={component.step1}
						step2={component.step2}
						step3={component.step3}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_Cta":
				return (
					<Cta
						key={index}
						heading={component.heading}
						body={component.body}
						button={component.button}
						backgroundImage={component.backgroundImage}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_CustomerReviews01":
				return (
					<CustomerReviews01
						key={index}
						heading={component.heading}
						body={component.body}
						reviews={component.reviews}
						button={component.button}
						backgroundColour={component.backgroundColour}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_CustomerReviews02":
				return (
					<CustomerReviews02
						key={index}
						heading={component.heading}
						body={component.body}
						reviews={component.reviews}
						button={component.button}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_Areas":
				return (
					<Areas
						key={index}
						heading={component.heading}
						body={component.body}
						popularLocations={component.popularLocations}
						button={component.button}
						button2={component.button2}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_BookingForm":
				return (
					<BookingForm
						key={index}
						heading={component.heading}
						body={component.body}
						backgroundImage={component.backgroundImage}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_SocialMediaPosts":
				return (
					<SocialMediaPosts
						key={index}
						heading={component.heading}
						secondHeading={component.secondHeading}
						body={component.body}
						socialLinks={component.socialLinks}
						socialPosts={component.socialPosts}
						backgroundColour={component.backgroundColour}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_BlogCatalogue":
				return (
					<BlogCatalogue
						key={index}
						heading={component.heading}
						smallHeading={component.smallHeading}
						body={component.body}
						button={component.button}
						backgroundColour={component.backgroundColour}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_GetInTouch":
				return (
					<GetInTouch
						key={index}
						heading={component.heading}
						body={component.body}
						contacts={component.contacts}
						teamMembers={component.teamMembers}
						backgroundColour={component.backgroundColour}
					/>
				);
			case "ListingsTemplate_Listingstemplatefields_OtherComponents_ExpertConsultation":
				return (
					<ExpertConsultation
						key={index}
						heading={component.heading}
						body={component.body}
						teamMember={component.teamMember}
					/>
				);
			default:
				return <div key={index}></div>;
		}
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Listings",
				item: {
					url: `${siteUrl}/listings`,
					id: `${siteUrl}/listings`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${property.name}`,
				item: {
					url: `${siteUrl}/listings/${createSlug(property.name)}-${
						property.fixedId
					}`,
					id: `${siteUrl}/listings/${createSlug(property.name)}-${
						property.fixedId
					}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${property.name} | Your Office People`}
				description={property.description?.replace(/â€™/g, "'")}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/listings/${createSlug(property.name)}-${
						property.fixedId
					}`,
					title: `${property.name} | Your Office People`,
					description: property.description?.replace(/â€™/g, "'"),
					images: [
						{
							url: `${property?.photos.split(",")[0]}`,
							width: 1920,
							height: 1080,
							alt: "Property main image",
						},
					],
				}}
			/>
			<section className="position-relative">
				<PropertyDetailHero property={property} />
				{wpPage.listingsTemplateFields.listingsTemplateComponents.map(
					(component, index) => renderListingTemplateComponent(component, index)
				)}
				{wpPage.listingsTemplateFields.otherComponents.map((component, index) =>
					renderComponent(component, index)
				)}
			</section>
		</Layout>
	);
};

export default PropertyDetailsTemplate;

export const pageQuery = graphql`
	query PropertyDetailsById($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		allWpTeamMember {
			nodes {
				title
				teamMemberFields {
					email
					name
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: WEBP
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					phoneNumbers {
						number
					}
				}
			}
		}
		property: airtableProperties(id: { eq: $id }) {
			subwayStation1
			live
			assignedTeamMember
			subwayStation1DistanceKm
			subwayStation1DurationMins
			subwayStation1Lines
			subwayStation2
			subwayStation2DistanceKm
			subwayStation2DurationMins
			subwayStation2Lines
			subwayStation3
			subwayStation3DistanceKm
			subwayStation3DurationMins
			subwayStation3Lines
			airtableId
			fixedId
			locationLatitude
			locationLongitude
			virtualTourLink
			name
			services
			amenities
			desksTo
			features
			description
			addressLineOne
			addressLineTwo
			city
			postCode
			type
			desksFrom
			rentPM
			videoId
			photos
			floorsAvailable {
				desksTo
				desksFrom
				name
				rentPM
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
		wpPage: wpListingsTemplate {
			title

			listingsTemplateFields {
				otherComponents {
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_TrustedBy {
						fieldGroupName
						heading
						logos {
							fieldGroupName

							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_HeaderLayout01 {
						body
						fieldGroupName
						heading
						imageOrVideo
						backgroundImage {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						button {
							target
							title
							url
						}
						backgroundVideo {
							altText
							sourceUrl
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_Layout01 {
						body
						fieldGroupName
						smallHeading
						heading
						button {
							target
							title
							url
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_Layout02 {
						body
						fieldGroupName
						heading
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_FeatureList01 {
						body
						fieldGroupName
						smallHeading
						heading
						propertyIds {
							id
							fieldGroupName
						}
						button {
							target
							title
							url
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_OurTeam01 {
						bannerBody
						bannerHeading
						bannerButton {
							target
							title
							url
						}
						body
						heading
						teamMember {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									email
									fieldGroupName
									name
									titlerole
									phoneNumbers {
										number
										fieldGroupName
									}
									images {
										altText
										gatsbyImage(
											width: 1920
											quality: 75
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
									featuredImage {
										altText
										gatsbyImage(
											width: 1920
											quality: 100
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						fieldGroupName
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_OurTeam02 {
						body
						fieldGroupName
						heading
						teamMembers {
							... on WpTeamMember {
								id
								teamMemberFields {
									body
									email
									name
									titlerole
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: WEBP
													quality: 80
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									images {
										altText
										gatsbyImage(
											width: 1920
											quality: 75
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_ProcessSteps {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						step1 {
							heading
							body
							fieldGroupName
							icon {
								altText
								sourceUrl
							}
						}
						step3 {
							body
							heading
							fieldGroupName
							icon {
								altText
								sourceUrl
							}
						}
						step2 {
							body
							fieldGroupName
							heading
							icon {
								altText
								sourceUrl
							}
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_Cta {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						backgroundImage {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_CustomerReviews01 {
						backgroundColour
						fieldGroupName
						body
						reviews {
							... on WpReview {
								id
								reviewFields {
									body
									name
									fieldGroupName
								}
								title
							}
						}
						heading
						button {
							target
							title
							url
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_CustomerReviews02 {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						reviews {
							... on WpReview {
								id
								title
								reviewFields {
									body
									fieldGroupName
									name
								}
							}
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_Areas {
						body
						fieldGroupName
						popularLocations {
							... on WpPopularLocation {
								id
								popularLocationsFields {
									url
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: WEBP
													quality: 80
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								title
								slug
							}
						}
						heading
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_BookingForm {
						body
						fieldGroupName
						heading
						backgroundImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_VideoIntro {
						body
						fieldGroupName
						imageOrVideo
						heading
						button {
							target
							title
							url
						}
						backgroundImage {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						backgroundVideo {
							altText
							sourceUrl
							mediaItemUrl
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_SocialMediaPosts {
						backgroundColour
						body
						heading
						secondHeading
						fieldGroupName
						socialLinks {
							url
							fieldGroupName
							icon {
								altText
								sourceUrl
							}
						}
						socialPosts {
							body
							fieldGroupName
							image {
								altText
								gatsbyImage(
									width: 1920
									quality: 75
									formats: [WEBP]
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_BlogCatalogue {
						backgroundColour
						fieldGroupName
						body
						smallHeading
						heading
						button {
							title
							url
							target
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_GetInTouch {
						backgroundColour
						body
						heading
						fieldGroupName
						contacts {
							heading
							fieldGroupName
							link {
								target
								title
								url
							}
							icon {
								altText
								sourceUrl
							}
						}
						teamMembers {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									email
									name
									titlerole
									phoneNumbers {
										number
										fieldGroupName
									}
									fieldGroupName
									featuredImage {
										altText
										gatsbyImage(
											width: 1920
											quality: 100
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
									images {
										altText
										gatsbyImage(
											width: 1920
											quality: 75
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpListingsTemplate_Listingstemplatefields_OtherComponents_ExpertConsultation {
						body
						fieldGroupName
						teamMember {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									email
									name
									titlerole
									phoneNumbers {
										fieldGroupName
										number
									}
									fieldGroupName
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: WEBP
													quality: 80
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									images {
										altText
										gatsbyImage(
											width: 1920
											quality: 75
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						heading
					}
				}
				fieldGroupName
				listingsTemplateComponents {
					... on WpListingsTemplate_Listingstemplatefields_ListingsTemplateComponents_PropertyDescription {
						fieldGroupName
					}
					... on WpListingsTemplate_Listingstemplatefields_ListingsTemplateComponents_PropertyMap {
						fieldGroupName
					}
					... on WpListingsTemplate_Listingstemplatefields_ListingsTemplateComponents_FeatureList02 {
						body
						fieldGroupName
						heading
						smallHeading
					}
				}
			}
		}
	}
`;
