import MapGL, { Marker, NavigationControl, Popup } from "@urbica/react-map-gl";
import { graphql } from "gatsby";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useGeolocated } from "react-geolocated";
import { Helmet } from "react-helmet";
import { MdOutlineClear } from "react-icons/md";
import Sticky from "react-stickynode";
import slugify from "slugify";
import Areas from "../components/Areas";
import BookingForm from "../components/BookingForm";
import CustomerReviews02 from "../components/CustomerReviews02";
import FilterModal from "../components/filter-modal";
import ImageSlider from "../components/image-slider";
import InputField from "../components/inputField";
import Layout from "../components/layout";
import Layout02 from "../components/Layout02";
import Layout03 from "../components/Layout03";
import ListingsPropertyCardNew from "../components/Listings-property-card-new";
import MapCardModal from "../components/map-card-modal";
import ProcessStep from "../components/ProcessSteps";
import PropertiesFilters from "../components/properties-filters";
import TrustedBy from "../components/TrustedBy";
import CartContext from "../context/CartContext";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
	require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const PopularLocationTemplate = ({ data, pageContext: { properties } }) => {
	const createSlug = (value) => {
		// Ensure the value is a string or provide a default empty string if it's not
		const stringValue = value ? String(value) : "";
		return slugify(stringValue);
	};

	const popLocation = data.popLocation;
	const {
		cart,
		settingVal,
		viewport,
		onLocationChange,
		numberOfPeople,
		filteredOffices,
		getDistanceFromLatLonInKm,
		distancePoint,
		OnRemoveFromCart,
		OnAddToCart,
		onLocationChangeScroll,
		onLocationChangePan,
		shortOffices,
		sortedBy,
		idLocation,
		capacityMinMax,
		showFilterMobile,
		selectedFeatures,
		selectedOfficeType,
		distanceAmount,
		budgetMinMax,
		budgetPerDesk,
	} = useContext(CartContext);

	const siteUrl = data?.site?.siteMetadata?.siteUrl;
	const bannerImageData = getImage(
		data.defaultBannerImage.localFile.childImageSharp.gatsbyImageData
	);

	const [shortOfficesNew, setShortOfficesNew] = useState([]);
	const [showFilter, setShowFilter] = useState(showFilterMobile);
	const [showForm, setShowForm] = useState(false);
	const [officeAmount, setOfficeAmount] = useState(5);
	const [mapChecked, setMapChecked] = useState(true);
	const [mapMobileChecked, setMapMobileChecked] = useState(false);
	const [urlLocation, setUrlLocation] = useState("");
	const [floors, setFloors] = useState([]);
	const [featuresProp, setFeaturesProp] = useState(selectedFeatures);
	const [type, setType] = useState(selectedOfficeType);
	const [resetting, setResetting] = useState(false);
	const [sorted, setSorted] = useState(sortedBy);
	const [radius, setRadius] = useState(distanceAmount);
	const [tempFeatures, setTempFeatures] = useState(selectedFeatures);
	const [modalShow, setModalShow] = useState(false);
	const [modalShowDesktop, setModalShowDesktop] = useState(false);
	const [modalProperty, setModalProperty] = useState("");
	const [popupInfo, setPopupInfo] = useState(null);

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: { enableHighAccuracy: true },
			userDecisionTimeout: 5000,
		});

	const mapRef = useRef();

	const bannerHeading = "Need something more specific?";
	const bannerBody = (
		<span>
			Use our free office calculator for instant pricing and size estimates -
			then request
			<br className="d-none d-xl-block" /> your tailored report!
		</span>
	);
	const bannerButtonTitle = "Office Calculator";
	const bannerButtonUrl = "/calculator";

	const round5 = (x) => Math.ceil(x / 5) * 5;

	useEffect(() => {
		if (typeof window !== "undefined") {
			const queryParameters = new URLSearchParams(window.location.search);
			const uniqueLocation = queryParameters.get("location") || "";
			setUrlLocation(uniqueLocation);
		}
	}, []);

	useEffect(() => {
		const locationMapping = {
			Bank: { lat: 51.5134724, lng: -0.090159 },
			"Canary Wharf and Docklands": { lat: 51.506156, lng: -0.021452 },
			Holborn: { lat: 51.517431, lng: -0.1204831 },
			Clerkenwell: { lat: 51.523727, lng: -0.105556 },
			"Covent Garden": { lat: 51.511736, lng: -0.122677 },
			"Kings Cross": { lat: 51.529108, lng: -0.122413 },
			Knightsbridge: { lat: 51.500844, lng: -0.166965 },
			"London Bridge / Borough": { lat: 51.5079111, lng: -0.0903026 },
			Marylebone: { lat: 51.522059, lng: -0.150455 },
			Mayfair: { lat: 51.511087, lng: -0.147058 },
			Midtown: { lat: 51.507322, lng: -0.127647 },
			Paddington: { lat: 51.516305, lng: -0.179242 },
			Shoreditch: { lat: 51.526669, lng: -0.079893 },
			Soho: { lat: 51.513163, lng: -0.131175 },
			"St Pauls": { lat: 51.513962, lng: -0.10114 },
			Victoria: { lat: 51.495979, lng: -0.141317 },
			"Piccadilly Circus": { lat: 51.5099786, lng: -0.1353476 },
			Westminster: { lat: 51.4975158, lng: -0.137249 },
		};

		if (urlLocation && locationMapping[urlLocation]) {
			const { lat, lng } = locationMapping[urlLocation];
			onLocationChange(lat, lng);
			settingVal(`${urlLocation}, London`, "locationValue");
		}
		// Removed onLocationChange and settingVal from dependency array
	}, [urlLocation]);

	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};

	useEffect(() => {
		if (typeof Window !== "undefined") {
			onLocationChange(
				popLocation.popularLocationsFields.latitude,
				popLocation.popularLocationsFields.longitude
			);
			settingVal(
				popLocation.popularLocationsFields.locationName,
				"locationValue"
			);

			settingVal(properties, "shortOffices");

			// Set the filtered properties passed from pageContext
			// settingVal(properties, "shortOffices");
		}
	}, []);

	const OnMapToggleClick = () => setMapChecked((prev) => !prev);

	const OnMapMobileToggleClick = () => setMapMobileChecked((prev) => !prev);

	const resetAll = () => {
		setFeaturesProp([]);
		setTempFeatures([]);
		setType("Office Type");
		setResetting((prev) => !prev);
		setSorted("Sort");
		setRadius(0.5);
		settingVal("Office Type", "selectedOffice");
		settingVal(capacityMinMax, "numberOfPeople");
		settingVal(budgetMinMax, "budgetPerDesk");
		settingVal("Sort", "sortedBy");
		settingVal(0.5, "distanceAmount");
		settingVal([], "selectedFeatures");
	};

	const handleClose = () => {
		setShowFilter(false);
		settingVal(false, "showFilterMobile");
	};

	const handleShow = () => setShowFilter(true);

	const {
		pageData: {
			pageFields: { components },
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${popLocation.seoFields.metaTitle}`,
				item: {
					url: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
					id: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
				},
			},
		],
	};

	useEffect(() => {
		setFeaturesProp(selectedFeatures);
		setType(selectedOfficeType);
		setSorted(sortedBy);
		setRadius(distanceAmount);
		// Removed settingVal from dependency array
	}, [selectedFeatures, selectedOfficeType, sortedBy, distanceAmount]);

	useEffect(() => {
		if (popupInfo?.node?.floorsAvailable) {
			setFloors(
				popupInfo.node.floorsAvailable
					.slice()
					.sort((a, b) => a.rentPM - b.rentPM)
			);
		}
	}, [popupInfo]);

	useEffect(() => {
		// Extract default values from context
		const defaultSelectedOfficeType = "Office Type";
		const defaultSelectedFeatures = [];
		const defaultDistanceAmount = 0.5;

		// capacityMinMax and budgetMinMax from context
		const defaultNumberOfPeople = capacityMinMax; // { min: 0, max: 75, reset: true }
		const defaultBudgetPerDesk = budgetMinMax; // { min: 0, max: 150000, minPerDesk: 0, maxPerDesk: 2000, perDesk: false }

		// Check if filters are at their default values
		const filtersAreDefault =
			(selectedOfficeType === defaultSelectedOfficeType ||
				selectedOfficeType === "Any") &&
			selectedFeatures.length === 0 &&
			numberOfPeople.min === defaultNumberOfPeople.min &&
			numberOfPeople.max === defaultNumberOfPeople.max &&
			budgetPerDesk.min === defaultBudgetPerDesk.min &&
			budgetPerDesk.max === defaultBudgetPerDesk.max &&
			budgetPerDesk.perDesk === defaultBudgetPerDesk.perDesk &&
			distanceAmount === defaultDistanceAmount;

		// Else, use the filtered and sorted offices
		let sortedOffices = filteredOffices.slice();

		// Sorting logic...
		if (sortedBy === "Distance" || sortedBy === "Sort") {
			sortedOffices.sort((a, b) => {
				const distanceA =
					getDistanceFromLatLonInKm(
						distancePoint.latitude,
						distancePoint.longitude,
						a.node.locationLatitude,
						a.node.locationLongitude
					) * 0.621371;
				const distanceB =
					getDistanceFromLatLonInKm(
						distancePoint.latitude,
						distancePoint.longitude,
						b.node.locationLatitude,
						b.node.locationLongitude
					) * 0.621371;
				return distanceA - distanceB;
			});
		} else if (sortedBy === "Lowest Price") {
			sortedOffices.sort((a, b) => a.node.rentPM - b.node.rentPM);
		} else if (sortedBy === "Highest Price") {
			sortedOffices.sort((a, b) => b.node.rentPM - a.node.rentPM);
		}
		settingVal(sortedOffices.slice(0, officeAmount), "shortOffices");
	}, [
		selectedOfficeType,
		selectedFeatures,
		numberOfPeople,
		budgetPerDesk,
		distanceAmount,
		filteredOffices,
		officeAmount,
		sortedBy,
		distancePoint.latitude,
		distancePoint.longitude,
		properties,
		capacityMinMax,
		budgetMinMax,
	]);

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={popLocation.seoFields?.metaTitle}
				description={popLocation.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
					title: `${popLocation.seoFields?.opengraphTitle}`,
					description: `${popLocation.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${popLocation.seoFields?.image?.localFile.publicURL}`,
							width: `${popLocation.seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${popLocation.seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${popLocation.seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<Container>
					<Row className="align-items-center justify-content-center">
						<Col md={12} xl={10}>
							<div className="pb-1 d-flex align-items-center">
								{/* <FaSearchLocation className="fs-2 opacity-25" /> */}
								<InputField propertiesPage />
							</div>
						</Col>
						{/* <Col className=" pb-2 d-xl-none" xs={2} sm={1}>
              {" "}
              <a
                className="align-self-center"
                style={{ cursor: "pointer" }}
                onClick={() => OnLocationChangeGeo()}
              >
                <BiCurrentLocation className="fs-3 card-link" />
              </a>
            </Col> */}
					</Row>
					<Row className="align-items-center justify-content-center mt-sm-4">
						<Col
							md={12}
							xl={10}
							className="d-flex justify-content-between align-items-center"
						>
							<PropertiesFilters
								features={featuresProp}
								type={type}
								resetting={resetting}
								sorted={sorted}
								radius={radius}
								setFeatures={setFeaturesProp}
								setType={setType}
								setResetting={setResetting}
								setSorted={setSorted}
								setRadius={setRadius}
							/>
							<div className="text-end d-none d-md-block">
								<Button
									onClick={() => OnMapToggleClick()}
									variant="transparent"
									className="px-4"
								>
									{`${mapChecked ? "Close" : "Show"} Map View`}
								</Button>
							</div>
						</Col>
					</Row>
				</Container>

				<section className="pt-sm-4 pb-sm-5 listing-content mt-4">
					<Container>
						<Row className="d-block d-md-none gx-3 gx-sm-0">
							<Col>
								<Row className="px-3 justify-content-between">
									{/* <button className="btn btn-white border border-dark-grey text-dark-grey py-2 px-6">

                      <BsSliders className="fs-2" />
                    </button> */}
									<Col className="text-center px-0" xs={12}>
										<FilterModal
											features={featuresProp}
											type={type}
											tempFeatures={tempFeatures}
											setTempFeatures={setTempFeatures}
											resetting={resetting}
											sorted={sorted}
											radius={radius}
											setFeatures={setFeaturesProp}
											setType={setType}
											setResetting={setResetting}
											setSorted={setSorted}
											setRadius={setRadius}
											handleClose={handleClose}
											handleShow={handleShow}
											showFilter={showFilter}
											setShowForm={setShowForm}
										/>
									</Col>
									<Col className="px-0" xs={12}>
										<Button
											onClick={() => OnMapMobileToggleClick()}
											variant="transparent"
											className="px-4 w-100 mt-3"
										>
											{`${mapMobileChecked ? "Close" : "Show"} Map View`}
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>

						{mapChecked === true && (
							<Row id="content" className="h-100 d-none d-md-flex">
								<Col lg={6}>
									{/* <Filters /> */}

									<Row
										className="position-relative mt-0 py-0 mb-0"
										style={{ index: 1 }}
									>
										{shortOffices?.map((property, i, a) => (
											<Col xs={12}>
												<div className="mb-5">
													{" "}
													<ListingsPropertyCardNew
														property={property}
														index={i}
													/>
													{i === (a.length > 2 ? 2 : a.length - 1) ||
													(i - 2) % 5 === 0 ? (
														<Row className="justify-content-center">
															<Col lg={8}>
																<div className="mt-5 px-3"></div>
															</Col>
														</Row>
													) : (
														""
													)}
												</div>
											</Col>
										))}
										{shortOffices?.length < 1 && (
											<Col>
												<h2 className="fs-3 mt-4">No matches</h2>
												<p>
													Try changing some of your filters or adjusting your
													search radius.
												</p>
												<Row className="mt-4 ">
													{" "}
													<Col md={5}>
														<Button
															className="w-100 btn fw-bold rounded-pill py-2 me-5 mb-4"
															style={{ cursor: "pointer" }}
															onClick={resetAll}
															variant="secondary"
														>
															RESET FILTERS
														</Button>
													</Col>
													<Col md={5}>
														<Button
															className="w-100 btn fw-bold rounded-pill  py-2"
															style={{ cursor: "pointer" }}
															onClick={() => {
																settingVal(5, "distanceAmount");
																setRadius(5);
															}}
															variant="transparent"
														>
															EXPAND TO 5 MILES
														</Button>
													</Col>
												</Row>
											</Col>
										)}
									</Row>
								</Col>

								<Col
									lg={6}
									style={{ minHeight: "100%" }}
									className=" position-relative "
								>
									{modalShow && (
										<MapCardModal
											show={modalShow}
											property={modalProperty}
											onHide={() => {
												setModalShow(false);
												setModalProperty("");
											}}
										/>
									)}

									<Sticky bottomBoundary="#content">
										<div className="">
											<MapGL
												ref={mapRef}
												style={{
													width: "100%",
													height: "100vh",
													borderRadius: "12px",
												}}
												mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
												accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbTMzNTBiemQxY3hwMmlzYXR5dGRmY3V3In0.LmvASVHMVHLrhorJsOBd0A"
												bounds={viewport?.bounds}
												latitude={viewport?.latitude}
												longitude={viewport?.longitude}
												zoom={viewport?.zoom}
												onViewportChange={(e) => onLocationChangePan(e)}
												viewportChangeMethod="flyTo"
											>
												<NavigationControl
													showZoom
													showCompass={false}
													position="top-right"
												/>

												{filteredOffices?.map((property, i) => {
													return (
														<Marker
															key={property.node.fixedId}
															longitude={property.node.locationLongitude}
															latitude={property.node.locationLatitude}
															onClick={() => {
																setPopupInfo(property);
															}}
														>
															{viewport?.latitude !==
																property.node.locationLatitude &&
																viewport?.longitude !==
																	property.node.locationLongitude &&
																popupInfo?.node.locationLatitude !==
																	property.node.locationLatitude &&
																popupInfo?.node.locationLongitude !==
																	property.node.locationLongitude && (
																	<StaticImage
																		quality="100"
																		src={`../images/flex-pin.svg`}
																		alt="Marker"
																		placeholder="blurred"
																		style={{
																			maxWidth: "32px",
																			cursor: "pointer",
																		}}
																		formats={["auto", "webp"]}
																		className="align-middle d-inline-block"
																	/>
																)}
															{((viewport?.latitude ===
																property.node.locationLatitude &&
																viewport?.longitude ===
																	property.node.locationLongitude) ||
																(popupInfo?.node.locationLatitude ===
																	property.node.locationLatitude &&
																	popupInfo?.node.locationLongitude ===
																		property.node.locationLongitude)) && (
																<StaticImage
																	quality="100"
																	src={`../images/flex-pin copy.svg`}
																	alt="Marker"
																	placeholder="blurred"
																	style={{
																		width: "55px",
																		cursor: "pointer",
																	}}
																	formats={["auto", "webp"]}
																	className="align-middle d-inline-block"
																/>
															)}
														</Marker>
													);
												})}

												{popupInfo && (
													<Popup
														latitude={popupInfo.node.locationLatitude}
														longitude={popupInfo.node.locationLongitude}
														closeButton={false}
														closeOnClick={false}
														onClose={() => setPopupInfo(null)}
														anchor="top"
														offset={[0, 20]}
													>
														<div
															style={{ zIndex: 2 }}
															className="popup-container position-relative"
														>
															<div style={{ zIndex: 5 }}>
																<div
																	className="px-3 pt-3 position-absolute end-0 top-0"
																	style={{ zIndex: 6 }}
																>
																	<MdOutlineClear
																		style={{ cursor: "pointer" }}
																		className="fs-4"
																		onClick={() => setPopupInfo(null)}
																	/>
																</div>

																<div className="bg-white h-100 mx-0 rounded-4">
																	<Row className="mx-0 g-0 align-items-center p-3">
																		<Col xs={12}>
																			<h5 className="mb-2 text-start">
																				{popupInfo.node.name}
																			</h5>
																			<div className="popup-image-slider">
																				<ImageSlider
																					imageDataArr={popupInfo.node.photos}
																					height="150px"
																				/>
																			</div>
																		</Col>
																		<Col
																			className="px-0 py-2 text-dark-bg mt-2"
																			style={{ minHeight: "100%" }}
																			xs={12}
																		>
																			{popupInfo.node.type ===
																				"Serviced Office" && (
																				<div className="d-flex align-items-center justify-content-between">
																					<p className="mb-0 small-text">
																						Per desk cost:
																					</p>
																					<h5 className="m-0">
																						£
																						{popupInfo.node.rentPM == null ||
																						popupInfo.node.rentPM === 0 ||
																						popupInfo.node.rentPM === 0.0
																							? "POA"
																							: `${round5(
																									popupInfo.node.rentPM
																							  ).toLocaleString("en-UK")}pcm`}
																					</h5>
																				</div>
																			)}
																			{(popupInfo.node.type ===
																				"Managed Office" ||
																				popupInfo.node.type ===
																					"Flex Lease") && (
																				<div className="d-flex align-items-center">
																					<p className="mb-0 small-text">
																						Price (From):
																					</p>
																					<h5 className="m-0">
																						£
																						{floors[0]?.rentPM == null ||
																						floors[0]?.rentPM === 0 ||
																						floors[0]?.rentPM === 0.0
																							? "POA"
																							: `${round5(
																									floors[0]?.rentPM
																							  ).toLocaleString("en-UK")}pcm`}
																					</h5>
																				</div>
																			)}

																			<div className="d-flex align-items-center justify-content-between">
																				<p className="mb-0 small-text">
																					Desks Available:
																				</p>
																				<h5 className="m-0">
																					{popupInfo.node.desksTo}
																				</h5>
																			</div>
																			<Button
																				href={`/listings/${createSlug(
																					popupInfo.node.name
																				)}-${popupInfo.node.fixedId}`}
																				variant="transparent"
																				className="px-4 py-2 mt-3 w-100"
																			>
																				View Details
																			</Button>
																		</Col>
																	</Row>
																</div>
															</div>
														</div>
													</Popup>
												)}
											</MapGL>
										</div>
									</Sticky>
								</Col>
							</Row>
						)}

						{mapMobileChecked === true && (
							<Row className="d-md-none">
								<Col>
									<div className="pb-5 mt-4">
										<MapGL
											ref={mapRef}
											style={{
												width: "100%",
												height: "70vh",
												borderRadius: "12px",
											}}
											mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
											accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbTMzNTBiemQxY3hwMmlzYXR5dGRmY3V3In0.LmvASVHMVHLrhorJsOBd0A"
											bounds={viewport?.bounds}
											latitude={viewport?.latitude}
											longitude={viewport?.longitude}
											zoom={viewport?.zoom}
											onViewportChange={(e) => onLocationChangePan(e)}
											viewportChangeMethod="flyTo"
										>
											<NavigationControl
												showZoom
												showCompass={false}
												position="top-right"
											/>
											{filteredOffices?.map((property, i) => {
												return (
													<Marker
														key={property.node.fixedId}
														longitude={property.node.locationLongitude}
														latitude={property.node.locationLatitude}
														onClick={() => setPopupInfo(property)}
														// onClick={() => {
														// 	setModalProperty(property);
														// 	setModalShow(true);
														// }}
														// onClick={() => onPinClick(property)}
													>
														{viewport?.latitude !==
															property.node.locationLatitude &&
															viewport?.longitude !==
																property.node.locationLongitude &&
															popupInfo?.node.locationLatitude !==
																property.node.locationLatitude &&
															popupInfo?.node.locationLongitude !==
																property.node.locationLongitude && (
																<StaticImage
																	quality="100"
																	src={`../images/flex-pin.svg`}
																	alt="Marker"
																	placeholder="blurred"
																	style={{
																		maxWidth: "28px",
																		cursor: "pointer",
																	}}
																	formats={["auto", "webp"]}
																	className="align-middle d-inline-block"
																/>
															)}
														{((viewport?.latitude ===
															property.node.locationLatitude &&
															viewport?.longitude ===
																property.node.locationLongitude) ||
															(popupInfo?.node.locationLatitude ===
																property.node.locationLatitude &&
																popupInfo?.node.locationLongitude ===
																	property.node.locationLongitude)) && (
															<StaticImage
																quality="100"
																src={`../images/flex-pin copy.svg`}
																alt="Marker"
																placeholder="blurred"
																style={{
																	maxWidth: "45px",
																	cursor: "pointer",
																}}
																formats={["auto", "webp"]}
																className="align-middle d-inline-block"
															/>
														)}
													</Marker>
												);
											})}
											{popupInfo && (
												<Popup
													latitude={popupInfo.node.locationLatitude}
													longitude={popupInfo.node.locationLongitude}
													closeButton={false}
													closeOnClick={false}
													onClose={() => setPopupInfo(null)}
													anchor="top"
													offset={[0, 20]}
												>
													<div
														style={{ zIndex: 2 }}
														className="popup-container position-relative"
													>
														<div style={{ zIndex: 5 }}>
															<div
																className="px-3 pt-3 position-absolute end-0 top-0"
																style={{ zIndex: 6 }}
															>
																<MdOutlineClear
																	style={{ cursor: "pointer" }}
																	className="fs-4"
																	onClick={() => setPopupInfo(null)}
																/>
															</div>

															<div className="bg-white h-100 mx-0 rounded-4">
																<Row className="mx-0 g-0 align-items-center p-3">
																	<Col xs={12}>
																		<h5 className="mb-2 text-start">
																			{popupInfo.node.name}
																		</h5>
																		<div className="popup-image-slider">
																			<ImageSlider
																				imageDataArr={popupInfo.node.photos}
																				height="150px"
																			/>
																		</div>
																	</Col>
																	<Col
																		className="px-0 py-2 text-dark-bg mt-2"
																		style={{ minHeight: "100%" }}
																		xs={12}
																	>
																		{popupInfo.node.type ===
																			"Serviced Office" && (
																			<div className="d-flex align-items-center justify-content-between">
																				<p className="mb-0 small-text">
																					Per desk cost:
																				</p>
																				<h5 className="m-0">
																					£
																					{popupInfo.node.rentPM == null ||
																					popupInfo.node.rentPM === 0 ||
																					popupInfo.node.rentPM === 0.0
																						? "POA"
																						: `${round5(
																								popupInfo.node.rentPM
																						  ).toLocaleString("en-UK")}pcm`}
																				</h5>
																			</div>
																		)}
																		{(popupInfo.node.type ===
																			"Managed Office" ||
																			popupInfo.node.type === "Flex Lease") && (
																			<div className="d-flex align-items-center">
																				<p className="mb-0 small-text">
																					Price (From):
																				</p>
																				<h5 className="m-0">
																					£
																					{floors[0]?.rentPM == null ||
																					floors[0]?.rentPM === 0 ||
																					floors[0]?.rentPM === 0.0
																						? "POA"
																						: `${round5(
																								floors[0]?.rentPM
																						  ).toLocaleString("en-UK")}pcm`}
																				</h5>
																			</div>
																		)}

																		<div className="d-flex align-items-center justify-content-between">
																			<p className="mb-0 small-text">
																				Desks Available:
																			</p>
																			<h5 className="m-0">
																				{popupInfo.node.desksTo}
																			</h5>
																		</div>
																		<Button
																			href={`/listings/${createSlug(
																				popupInfo.node.name
																			)}-${popupInfo.node.fixedId}`}
																			variant="transparent"
																			className="px-4 py-2 mt-3 w-100"
																		>
																			View Details
																		</Button>
																	</Col>
																</Row>
															</div>
														</div>
													</div>
												</Popup>
											)}
										</MapGL>
									</div>
								</Col>
							</Row>
						)}

						{mapChecked === false && (
							<Row id="content" className="h-100 d-none d-md-flex">
								<Col>
									{/* <Filters /> */}
									<Row
										className="position-relative mt-0  py-0 mb-0"
										style={{ index: 1 }}
									>
										{shortOffices?.map((property, i, a) => (
											<>
												<Col className="mb-5" xs={12} lg={6}>
													{" "}
													<ListingsPropertyCardNew
														property={property}
														index={i}
													/>
												</Col>
											</>
										))}
										{shortOffices?.length < 1 && (
											<Col>
												<h2 className="fs-3 mt-4">No matches</h2>
												<p>
													Try changing some of your filters or adjusting your
													search radius.
												</p>
												<Row className="mt-4 ">
													{" "}
													<Col md={5}>
														<Button
															className="w-100 btn fw-bold rounded-pill py-2 me-5 mb-4"
															style={{ cursor: "pointer" }}
															onClick={resetAll}
															variant="secondary"
														>
															RESET FILTERS
														</Button>
													</Col>
													<Col md={5}>
														<Button
															className="w-100 btn fw-bold rounded-pill  py-2"
															style={{ cursor: "pointer" }}
															onClick={() => {
																settingVal(5, "distanceAmount");
																setRadius(5);
															}}
															variant="transparent"
														>
															EXPAND TO 5 MILES
														</Button>
													</Col>
												</Row>
											</Col>
										)}
									</Row>
								</Col>
							</Row>
						)}
						{mapMobileChecked === false && (
							<Row id="content" className="h-100 d-md-none mt-4">
								<Col>
									{/* <Filters /> */}

									<Row
										className="position-relative mt-0 py-0 mb-0"
										style={{ index: 1 }}
									>
										{shortOffices?.map((property, i, a) => (
											<>
												<Col className="mb-5" xs={12} lg={6}>
													{" "}
													<ListingsPropertyCardNew
														property={property}
														index={i}
													/>
												</Col>
											</>
										))}
										{shortOffices?.length < 1 && (
											<Col>
												<h2 className="fs-3 mt-4">No matches</h2>
												<p>
													Try changing some of your filters or adjusting your
													search radius.
												</p>
												<Row className="mt-4 ">
													{" "}
													<Col md={5}>
														<Button
															className="w-100 btn fw-bold rounded-pill py-2 me-5 mb-4"
															style={{ cursor: "pointer" }}
															onClick={resetAll}
															variant="secondary"
														>
															RESET FILTERS
														</Button>
													</Col>
													<Col md={5}>
														<Button
															className="w-100 btn fw-bold rounded-pill py-2"
															style={{ cursor: "pointer" }}
															onClick={() => {
																settingVal(5, "distanceAmount");
																setRadius(5);
															}}
															variant="transparent"
														>
															EXPAND TO 5 MILES
														</Button>
													</Col>
												</Row>
											</Col>
										)}
									</Row>
								</Col>
							</Row>
						)}
						{mapMobileChecked === false &&
							shortOffices?.length > 0 &&
							filteredOffices?.length > shortOffices?.length && (
								<Row>
									<Col
										lg={mapChecked === true ? 6 : 12}
										className="text-center"
									>
										<Button
											onClick={() => window.scrollTo(0, 0)}
											variant="secondary houschka w-100 w-md-auto mb-3 mb-md-0 px-4 "
										>
											Refine your search
										</Button>
										<Button
											onClick={() => setOfficeAmount(officeAmount + 4)}
											variant="transparent"
											className="w-100 ms-md-3 houschka w-md-auto px-4"
										>
											Load more
										</Button>
									</Col>
								</Row>
							)}
					</Container>
				</section>
				<Layout03
					heading={`Office space in ${data.popLocation?.popularLocationsFields?.locationName}`}
					body={data.popLocation?.popularLocationsFields?.description}
					image={
						data.popLocation?.popularLocationsFields?.featuredImage ||
						data.defaultImage1
					}
					button={{ title: "Book a consultation", url: "/contact-us" }}
				/>
				<Layout02
					heading={
						data.popLocation?.popularLocationsFields?.layout02PopLocations
							?.heading || "On the hunt for an office? We've got you covered."
					}
					body={
						data.popLocation?.popularLocationsFields?.layout02PopLocations
							?.body ||
						"<p>Start your journey to find the office space that is spot-on for your business. Let our brilliant team guide you through the process, from finding the ideal location to negotiating the best terms, uncovering office space gems and tailoring solutions to match your needs. We'll help you find a workspace that inspires and motivates your team.</p>"
					}
					image={
						data.popLocation?.popularLocationsFields?.layout02PopLocations
							?.image || data.defaultImage2
					}
					button={
						data.popLocation?.popularLocationsFields?.layout02PopLocations
							?.button || { title: "Book a consultation", url: "/contact-us" }
					}
				/>

				<ProcessStep
					heading={
						data.popLocation?.popularLocationsFields?.processStepsPopLocations
							?.heading ||
						"We make the process of finding your new office effortless"
					}
					body={
						data.popLocation?.popularLocationsFields?.processStepsPopLocations
							?.body ||
						"<p> Offices that tick all the boxes. We’re expert listeners and will leave no stone unturned to match your needs, preferences, and budget, ensuring you land an office that's the right fit for your business.</p>"
					}
					button={
						data.popLocation?.popularLocationsFields?.processStepsPopLocations
							?.button || { title: "Talk to us", url: "/contact-us" }
					}
					step1={{
						heading:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step1?.heading || "Talk with an expert",
						body:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step1?.body ||
							"<p> We’ll connect you to your own office expert who will be with you from the start and throughout your journey</p>",
						icon:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step1?.icon || data.defaultIcon1,
					}}
					step2={{
						heading:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step1?.heading || "Take a peek",
						body:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step1?.body ||
							"<p> Meet your office expert and future office face-to-face to get a real feel for the space.</p>",
						icon:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step2?.icon || data.defaultIcon2,
					}}
					step3={{
						heading:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step1?.heading || "It’s the one",
						body:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step1?.body ||
							"<p>Once you’ve chosen the favourite, your office expert helps negotiate the best deal on your behalf and even organises key handover. It really couldn’t be simpler.</p>",
						icon:
							data.popLocation?.popularLocationsFields?.processStepsPopLocations
								?.step3?.icon || data.defaultIcon3,
					}}
				/>

				{components &&
					components.length > 0 &&
					components.map((sectionInfo) => (
						<>
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_TrustedBy" && (
								<TrustedBy
									heading={sectionInfo?.heading}
									logos={sectionInfo?.logos}
								/>
							)}
						</>
					))}

				<BookingForm
					heading={
						data.popLocation?.popularLocationsFields?.bookingFormPopLocations
							?.heading || "Get in touch"
					}
					body={
						data.popLocation?.popularLocationsFields?.bookingFormPopLocations
							?.body ||
						"<p>Looking for your dream office? Let’s make it happen! Just fill out the form below and we’ll take care of the rest!</p>"
					}
					backgroundImage={
						data.popLocation?.popularLocationsFields?.bookingFormPopLocations
							?.backgroundImage || data.defaultImage3
					}
				/>

				{components &&
					components.length > 0 &&
					components.map((sectionInfo) => (
						<>
							{sectionInfo.fieldGroupName ===
								"Page_Pagefields_Components_CustomerReviews02" && (
								<CustomerReviews02
									heading={
										data.popLocation?.popularLocationsFields
											?.customerReviews02PopLocations?.heading ||
										"Don’t just take our word for it"
									}
									body={
										data.popLocation?.popularLocationsFields
											?.customerReviews02PopLocations?.body ||
										"<p>Hear from our happy clients what we bring to the office table! From finding the dream space to handling the twists and turns of lease negotiations, our team is all about making your office hunt a breeze.</p>"
									}
									reviews={sectionInfo?.reviews}
									button={
										data.popLocation?.popularLocationsFields
											?.customerReviews02PopLocations?.button || {
											title: "Book a consultation",
											url: "/contact-us",
										}
									}
								/>
							)}
						</>
					))}
				<Areas
					heading={
						data.popLocation?.popularLocationsFields?.areasPopLocations
							?.heading || "Not sure what you’re after? Get inspired!"
					}
					body={
						data.popLocation?.popularLocationsFields?.areasPopLocations?.body ||
						"<p>Have a look at our handpicked locations offering you the ideal blend of style and functionality for your new office space. Whether you're after a trendy communal vibe or a historic gem, our top office location picks are here to level up your business.</p>"
					}
					button={
						data.popLocation?.popularLocationsFields?.areasPopLocations
							?.button || { title: "Book a consultation", url: "/contact-us" }
					}
					button2={
						data.popLocation?.popularLocationsFields?.areasPopLocations
							?.button2 || { title: "View more", url: "/listings" }
					}
					popularLocations={data?.allWpPopularLocation.nodes}
				/>
			</Layout>
		</>
	);
};

export default PopularLocationTemplate;

export const data = graphql`
	query PopularLocationById($id: String!) {
		allWpPopularLocation(limit: 4, filter: { id: { ne: $id } }) {
			nodes {
				popularLocationsFields {
					url
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: WEBP
									quality: 80
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				title
			}
		}
		popLocation: wpPopularLocation(id: { eq: $id }) {
			seoFields {
				metaTitle
				metaDescription
				opengraphDescription
				opengraphTitle
				productSchema
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
						publicURL
					}
				}
			}
			title
			popularLocationsFields {
				layout02PopLocations {
					body
					heading
					button {
						title
						url
					}
					image {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 70
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				bookingFormPopLocations {
					body
					heading
					backgroundImage {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 70
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				customerReviews02PopLocations {
					body
					heading
					button {
						title
						url
					}
				}
				areasPopLocations {
					body
					heading
					button {
						title
						url
					}
					button2 {
						title
						url
					}
				}
				processStepsPopLocations {
					heading
					body
					button {
						title
						url
					}
					step1 {
						heading
						body
						icon {
							altText
							mediaItemUrl
							sourceUrl
						}
					}
					step2 {
						body
						heading
						icon {
							altText
							mediaItemUrl
							sourceUrl
						}
					}
					step3 {
						body
						heading
						icon {
							altText
							mediaItemUrl
							sourceUrl
						}
					}
				}

				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 70
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}

				description
				latitude
				longitude
				locationName

				property1
				property2
				property3
				property4
				property5
				url
			}
		}

		allAirtableProperties(
			filter: {
				isFloor: { nin: 1 }
				hasFeatures: { eq: 1 }
				hasPhotos: { eq: 1 }
				live: { eq: true }
			}
		) {
			edges {
				node {
					addressLineOne
					desksFrom
					desksTo
					live
					fixedId
					subwayStation1
					subwayStation1DistanceKm
					subwayStation1DurationMins
					subwayStation2
					subwayStation2DistanceKm
					subwayStation2DurationMins
					subwayStation3
					subwayStation3DistanceKm
					subwayStation3DurationMins
					type
					provider
					rentPM
					airtableId
					locationLatitude
					locationLongitude
					name
					city
					services
					amenities
					features
					photos
				}
			}
		}
		heroInterior: wpMediaItem(title: { eq: "Frame 27" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		defaultBannerImage: wpMediaItem(title: { eq: "Frame 27" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		defaultImage1: wpMediaItem(title: { eq: "Mayfair-New" }) {
			altText

			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		defaultImage2: wpMediaItem(title: { eq: "default" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		defaultImage3: wpMediaItem(title: { eq: "Form Background" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 100
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		defaultIcon1: wpMediaItem(title: { eq: "Talk with an expert" }) {
			altText
			mediaItemUrl
			sourceUrl
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		defaultIcon2: wpMediaItem(title: { eq: "Take a peek" }) {
			altText
			mediaItemUrl
			sourceUrl
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		defaultIcon3: wpMediaItem(title: { eq: "It’s the one" }) {
			altText
			mediaItemUrl
			sourceUrl
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
		pageData: wpPage(slug: { eq: "listing-page" }) {
			pageFields {
				components {
					... on WpPage_Pagefields_Components_Layout02 {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_CustomerReviews02 {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						reviews {
							... on WpReview {
								id
								title
								reviewFields {
									body
									fieldGroupName
									name
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_TrustedBy {
						fieldGroupName
						heading
						logos {
							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_ProcessSteps {
						body
						fieldGroupName
						button {
							target
							title
							url
						}
						heading
						step1 {
							body
							fieldGroupName
							heading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						step2 {
							body
							heading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						step3 {
							body
							heading
							icon {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
